import React, { Component } from 'react';
import Loader from '../../components/Layout/Loader/Loader';
import AddFolderModal from '../../components/Modals/AddFolderModal';
import UploadFileModal from '../../components/Modals/UploadFileModal';
import ConfirmModal from '../../components/Modals/ConfirmModal';
import { addFolder, updateFolder, deleteFolder, addFile, getFile, deleteFile, uploadFile, updateFile } from '../../shared/Api';
import { Folder, File } from '../../components/Icons/Svg';
import * as moment from 'moment';
import { getMessages } from '../../components/Messages/GetMessages';
import MoveFileModal from '../../components/Modals/MoveFileModal';
import { AuthContext } from '../../shared/Auth';
import { Timestamp } from 'firebase/firestore';
import { saveFileData, saveFileEntity } from '../../shared/api/FilesApi';
import { toFileEntity, toFileEntityDto } from '../../shared/data/folder/FolderStructure';
import { buildJobFileEntityPath, buildJobFileUploadPath } from '../../shared/data/job/Job';
import InputTextModal from '../../components/Modals/InputTextModal';

class JobDocuments extends Component {
    static contextType = AuthContext;
    user = this.context.currentUser.userProfile;
    canEdit = this.user.permissions !== 'crewmember';
    
    state = {
        folderModalShow: false,
        fileModalShow: false,
        deleteFolderModalShow: false,
        deleteFileModalShow: false,
        showEditFileNameModal: false,
        folderName: '',
        folderId: null,
        fileName: '',
        fileDescription: '',
        fileId: null,
        file: null,
        files: [],
        creatingFolder: false,
        deletingFolder: false,
        uploadingFile: false,
        selectedFolder: null,
        deletingFile: false,
        moveFileModalShow: false,
        selectedModalFolder: null,
        moveFile: null,
        movingFolder: false,
    }

    componentDidMount() {
        this.props.setTabActive('documents');
    }

    currentDocuments = () => {        
        if(!this.state.selectedFolder)
            return this.props.documents.sort((a, b)=>((a.uploadedAt??Timestamp.now()) > (b.uploadedAt??Timestamp.now())) ? -1 : 1);

        return this.getFolderContentById(this.props.documents, this.state.selectedFolder.id)
    }

    getFolderContentById = (folders, folderId) => {
        for (const folder of folders) {
          if (folder.id === folderId) {
            return [...folder?.subfolders ?? [], ...folder?.files ?? []];
          }
          if (folder.subfolders && folder.subfolders.length > 0) {
            const foundFolder = this.getFolderContentById(folder.subfolders, folderId);
            if (foundFolder) {
              return foundFolder;
            }
          }
        }
        return null;
      };

    findFolderById = (structure, id) => {
        for (const item of structure) {
          // Check if the current item is the folder we're looking for
          if (item.id === id) {
            return item;
          }
          // If the current item has subfolders, recursively search them
          if (item.subfolders && item.subfolders.length > 0) {
            const found = this.findFolderById(item.subfolders, id);
            if (found) return found;
          }
        }
        // Return null if the folder is not found
        return null;
      };

    currentModalDocuments = () => {
        if(!this.state.selectedModalFolder)
            return this.props.documents.filter(x => x.type === 'folder');

        let folder = this.props.documents.find(x => x.id === this.state.selectedModalFolder.id);
        if(!folder) {
            return [];
        }

        return folder.subfolders;
    }

    getFolderPath = (folder) => {
        if(!folder) 
            return "/";

        if(!folder.categoryId) 
            return "/" + folder.name;    

        if(folder.categoryId) {
            const parent = this.props.documents.find(x => x.id === folder.categoryId);
            return "/" + parent.name + "/" + folder.name;
        }

        return "/";
    }

    moveFileToFolder = async () => {
        this.setState({ movingFolder: true });

        await updateFile(this.props.inventoryId, this.props.job.id, this.state.moveFile.id, 
            { categoryID: this.state.selectedModalFolder ? this.state.selectedModalFolder.id : null });

        await this.props.loadDocuments(this.props.job.id, this.props.inventoryId, this.props.companyId);   
        this.setState({ movingFolder: false, selectedModalFolder: null, moveFileModalShow: false, moveFile: null });
    }

    saveFolder = async () => {
        this.setState({ creatingFolder: true });

        if (this.state.folderId) {
            await updateFolder(this.props.inventoryId, this.props.job.id, this.state.folderId, this.state.folderName);
        } else {
            await addFolder(this.props.inventoryId, this.props.job.id, this.state.folderName, 
                this.state.selectedFolder ? this.state.selectedFolder.id : null);
        }

        await this.props.loadDocuments(this.props.job.id, this.props.inventoryId, this.props.companyId);
        this.setState({ creatingFolder: false, folderModalShow: false, folderName: '', folderId: null });
    }


    /**
     * @deprecated This method is deprecated in favour of the {@link uploadFiles} method.
     */
    uploadFile = async () => {
        this.setState({ uploadingFile: true });
        
        await Promise.all(this.state.files.map(async (x) => {
            const file = {
                filename: x.name,
                name: x.name,
                description: x.description,
                categoryID: this.state.selectedFolder ? this.state.selectedFolder.id : null,
                uploadedAt: new Date()
            };

            const result = await addFile(this.props.inventoryId, this.props.job.id, file);
            const document = await getFile(this.props.inventoryId, this.props.job.id, result.data);
            await uploadFile(this.props.companyId, 'Files', this.props.job.id, document.data.uploadedAt, x.name, x.file);
        }));
        
        await this.props.loadDocuments(this.props.job.id, this.props.inventoryId, this.props.companyId);
        this.setState({ uploadingFile: false, file: null, files: [], fileModalShow: false });
    }

    uploadFiles = async () =>{

        await Promise.all(this.state.files.map(async(file, index)=>{
            const createdAt = Timestamp.fromMillis(Timestamp.now().toMillis()+(index*5000))
            const fileEntity = {
                name: file.description, 
                fileName: file.name,
                parentId: this.state.selectedFolder ? this.state.selectedFolder.id : null,
                createdBy: this.context.currentUser?.displayName,
                createdAt: createdAt,
                fileType: "document",
                description: file.description,
                file: file.file
            }

            const fileUpload = {
                fileEntityPath: buildJobFileEntityPath(this.props.inventoryId ?? "", this.props.job.id ?? ""),
                file: fileEntity.file,
                fileUploadState: "upload",
                fileUploadPath: buildJobFileUploadPath(
                  this.context.currentUser?.userProfile?.companyID ?? "",
                  this.props.job.id ?? "",
                  fileEntity?.createdAt?.seconds?.toString() ?? ""
                ),
                fileEntityDto: toFileEntityDto(fileEntity),
                fileDocumentId: null,
              };

              await saveFileData(fileUpload)
        }))

        await this.props.loadDocuments(this.props.job.id, this.props.inventoryId, this.props.companyId);
        this.setState({ uploadingFile: false, file: null, files: [], fileModalShow: false });
    }

    handleFolderNameChange = (event) => {
        this.setState({ folderName: event.target.value });
    }

    handleFileNameChange = (event) => {
        this.setState({ fileName: event.target.value });
    }

    handleFileDescriptionChange = (event, index) => {
        const files = [...this.state.files];
        files[index] = {...files[index], description: event.target.value };

        this.setState({ files: files });
    }

    getFileIcon = (filename) => {
        switch (filename?.split('.')?.pop()) {
            default:
                return <File />
        }
    }

    toggleEditModal = (folder) => {
        this.setState({ folderId: folder.id, folderName: folder.name, folderModalShow: true });
    }

    toggleMoveFileModalShow = (file) => {
        this.setState({ selectedModalFolder: null, moveFileModalShow: true, moveFile: file });
    }

    setSelectedModalFolder = (folder) => {
        this.setState({ selectedModalFolder: folder });
    }

    toggleDeleteModal = (folder) => {
        this.setState({ folderId: folder.id, deleteFolderModalShow: true });
    }

    toggleDeleteFileModal = (file) => {
        this.setState({ fileId: file.id, deleteFileModalShow: true });
    }

    toggleRenameFileModal = (file) => {
        this.setState({ file: file, showEditFileNameModal: true });
    }

    changeFileName = async (name) => {

        if(this.state.file){
            const fileToChangeName = toFileEntity(this.state.file, this.state.file.id)
            fileToChangeName.name = name

            await saveFileEntity(toFileEntityDto(fileToChangeName), buildJobFileEntityPath(this.props.inventoryId ?? "", this.props.job.id ?? ""), fileToChangeName.id)
        }

        this.setState({ showEditFileNameModal: false, fileName: "", fileId:null}); 
        await this.props.loadDocuments(this.props.job.id, this.props.inventoryId, this.props.companyId);
    }

    handleModalBack = () => {
        if(this.state.selectedModalFolder && this.state.selectedModalFolder.categoryId) {
            const folder = this.props.documents.find(x => x.id === this.state.selectedModalFolder.categoryId);
            if(folder) {
                this.setState({ selectedModalFolder: folder });
                return;
            }
        }

        this.setState({ selectedModalFolder: null });
    }

    handleBack = () => {
        if(this.state.selectedFolder && this.state.selectedFolder.categoryId) {
            const folder = this.findFolderById(this.props.documents, this.state.selectedFolder.categoryId)//this.props.documents.find(x => x.id === this.state.selectedFolder.categoryId);
            if(folder) {
                this.setState({ selectedFolder: folder });
                return;
            }
        }
        this.setState({ selectedFolder: null });
    }

    deleteFolder = async () => {
        this.setState({ deletingFolder: true });
        await deleteFolder(this.props.inventoryId, this.props.job.id, this.state.folderId);
        await this.props.loadDocuments(this.props.job.id, this.props.inventoryId, this.props.companyId);
        this.setState({ deleteFolderModalShow: false, folderName: '', folderId: null, deletingFolder: false });
    }

    deleteFile = async () => {
        this.setState({ deletingFile: true });
        //TODO: added new saveFile method which will delete file from storage too
        //this one only deletes file object in database
        const result = await deleteFile(this.props.inventoryId, this.props.job.id, this.state.fileId);
        await this.props.loadDocuments(this.props.job.id, this.props.inventoryId, this.props.companyId);
        this.setState({ deleteFileModalShow: false, fileName: '', fileId: null, deletingFile: false });
    }

    onImageDrop = (acceptedFiles) => {
        let files = [...this.state.files];
        files = files.concat(acceptedFiles.map((x) => { return { name: x.name, description: '', file: x } }));

        this.setState({ files: files });
    }

    onImageRemove = (index) => {
        const arr = [...this.state.files];
        arr.splice(index, 1);
        this.setState({ files: arr });
    }

    getFolderLastModifiedDate = (folder) => {
        const files = folder?.files ?? folder.subfolders ?? [];
        if (files.length === 0) return  moment(folder?.createdAt?.toDate())?.format("MM/DD/YY, hh:mma") ?? "-"
      
        const mostRecentFile = files.reduce((latest, current) => {
          const latestDate = latest?.uploadedAt?.toDate() ?? latest?.createdAt?.toDate();
          const currentDate = current?.uploadedAt?.toDate() ?? current?.createdAt?.toDate();;
          return currentDate > latestDate ? current : latest;
        });
      
        return moment(mostRecentFile?.uploadedAt?.toDate())?.format("MM/DD/YY, hh:mma") ?? "-"
    }

    render() {
        if (!this.props.documents)
            return <Loader height={340} />;

        const documents = this.currentDocuments();

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-xl-12">
                        {this.state.selectedFolder &&
                        <div className="form-group float-left">
                            <button onClick={this.handleBack}
                                className='btn btn-primary font-weight-bold mr-4 w-100'>Back</button>
                        </div>}
                        {(!this.state.selectedFolder || this.state.selectedFolder.level < 2) &&
                        <div className="form-group float-right ml-2">
                            <button onClick={() => { this.setState({ folderModalShow: true }); }}
                                className='btn btn-primary font-weight-bold mr-4 w-100'>Add Folder</button>
                        </div>}
                        <div className="form-group float-right">
                            <button onClick={() => { this.setState({ fileModalShow: true, files: [] }); }}
                                className='btn btn-primary font-weight-bold mr-4 w-100'>Upload File</button>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12 pt-10 pt-xl-0">
                        <div className="card card-custom card-stretch" id="kt_todo_view">
                            <div className="card-body pt-10">
                                {this.state.selectedFolder && 
                                    <h5 className="mb-12">{this.state?.selectedFolder?.name ?? "No Name"}</h5>
                                }
                                {(documents?.length ?? 0) > 0 ?
                                <>
                                {documents.map((x,index) => (
                                    <div key={"jb-dcmnt"+index} className="d-flex align-items-center mb-10">
                                        <div className="symbol symbol-40 symbol-light-success mr-5">
                                            {x.type === "folder" ? <Folder /> : this.getFileIcon(x.filename ?? x.fileName)}
                                        </div>
                                        {x.type === 'folder' ?
                                            <div className="d-flex flex-column flex-grow-1 font-weight-bold">
                                                <a role="button" onClick={(e) => { e.preventDefault(); this.setState({ selectedFolder: x })}} className="text-dark text-hover-primary mb-1 font-size-lg">{x.name}</a>
                                                <span className="text-muted">Last modified at: {this.getFolderLastModifiedDate(x)}</span>
                                            </div> :
                                            <div className="d-flex flex-column flex-grow-1 font-weight-bold">
                                                <a target="_blank" href={x.fileLink ?? x.url} >
                                                <div className="text-dark mb-1 font-size-lg"> {x.description ?? x.name}</div>
                                                <div className="text-muted">Created at: {moment(x.createdAt?.toDate() ?? x.uploadedAt?.toDate()).format("MM/DD/YY, hh:mma")}</div>
                                                <div className="text-muted">Uploaded by: {x.userName ?? x.createdBy}</div>
                                                <div className="text-muted">File name: {x.filename ?? x.fileName}</div>

                                                </a>
                                            </div>}
                                        {!x.readOnly && <div className="dropdown dropdown-inline ml-2" data-toggle="tooltip" title="Quick actions" data-placement="left">
                                            <a href="#" className="btn btn-hover-light-primary btn-sm btn-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i className="ki ki-bold-more-hor" />
                                            </a>
                                            {x.type === 'folder' ?
                                                <div className="dropdown-menu p-0 m-0 dropdown-menu-md dropdown-menu-right">
                                                    <ul className="navi navi-hover">
                                                    {
                                                        this.canEdit && 
                                                        <li className="navi-item">
                                                            <a role="button" onClick={() => this.toggleDeleteModal(x)} className="navi-link">
                                                                <span className="navi-text">
                                                                    Delete
                                                        </span>
                                                            </a>
                                                        </li>
                                                    }
                                                        <li className="navi-item">
                                                            <a role="button" onClick={() => this.toggleEditModal(x)} className="navi-link">
                                                                <span className="navi-text">
                                                                    Rename
                                                        </span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div> :
                                                <div className="dropdown-menu p-0 m-0 dropdown-menu-md dropdown-menu-right">
                                                    <ul className="navi navi-hover">
                                                        <li className="navi-item">
                                                            <a role="button" onClick={() => this.toggleMoveFileModalShow(x)} className="navi-link">
                                                                <span className="navi-text">
                                                                    Move
                                                        </span>
                                                            </a>
                                                        </li>
                                                        {
                                                            this.canEdit && 
                                                            <li className="navi-item">
                                                            <a role="button" onClick={() => this.toggleDeleteFileModal(x)} className="navi-link">
                                                                <span className="navi-text">
                                                                    Delete
                                                                </span>
                                                            </a>
                                                        </li>
                                                        }
                                                        <li className="navi-item">
                                                            <a role="button" onClick={() => this.toggleRenameFileModal(x)} className="navi-link">
                                                                <span className="navi-text">
                                                                    Rename
                                                        </span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>}
                                        </div>}
                                    </div>
                                ))}
                                </> : 
                                <div className="d-flex align-items-center mb-10">
                                    <div className="d-flex flex-column flex-grow-1 font-weight-bold align-items-center">
                                        <i className="flaticon2-document step-graphic" />
                                        <span className="font-italic">There aren't any documents for this job at the moment. You can easily add them by clicking on the buttons above.</span>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <InputTextModal
                id={`multiple-items-edit-name-modal`}
                show={this.state.showEditFileNameModal}
                title="File name"
                body="Enter caption"
                yesButton={{
                  title: "Yes",
                  spinner: false,
                  onClick: (value) => {
                    this.changeFileName(value)
                  },
                }}
                noButton={{
                  title: "Cancel",
                  onClick: (value) => {
                    this.setState({ showEditFileNameModal: false, fileName: "", fileId:null }); 
                  },
                }}

                />
                <AddFolderModal
                    show={this.state.folderModalShow}
                    toggle={() => { 
                        this.setState({ folderModalShow: false, folderName: '', folderId: null }); 
                    }}
                    folderName={this.state.folderName}
                    handleChange={this.handleFolderNameChange}
                    save={this.saveFolder}
                    saving={this.state.creatingFolder}
                />
                <MoveFileModal
                    show={this.state.moveFileModalShow}
                    toggle={() => { this.setState({ moveFileModalShow: false }); }}
                    folderName={this.state.folderName}
                    handleChange={this.handleFolderNameChange}
                    getFolders={this.currentModalDocuments}
                    setSelectedFolder={this.setSelectedModalFolder}
                    selectedFolder={this.state.selectedModalFolder}
                    save={this.moveFileToFolder}
                    getFolderPath={this.getFolderPath}
                    handleBack={this.handleModalBack}
                    saving={this.state.movingFolder}
                    moveFile={this.state.moveFile}
                    moveFileToFolder={this.moveFileToFolder}

                />
                <UploadFileModal
                    show={this.state.fileModalShow}
                    toggle={() => { this.setState({ fileModalShow: false }); }}
                    fileName={this.state.fileName}
                    fileDescription={this.state.fileDescription}
                    file={this.state.file}
                    files={this.state.files}
                    handleNameChange={this.handleFileNameChange}
                    handleDescriptionChange={(e, index) => this.handleFileDescriptionChange(e, index)}
                    save={this.uploadFiles}
                    saving={this.state.uploadingFile}
                    onImageDrop={this.onImageDrop}
                    onRemove={this.onImageRemove}
                />
                <ConfirmModal
                    id={"delete_folder"}
                    show={this.state.deleteFolderModalShow}
                    title="Delete folder"
                    body={getMessages('delete_folder')}
                    yesButton={{
                        title: "Yes",
                        onClick: () => { this.deleteFolder(); },
                        spinner: this.state.deletingFolder
                    }}
                    noButton={{
                        title: "Cancel",
                        onClick: () => { this.setState({ deleteFolderModalShow: false }) }
                    }}
                />
                <ConfirmModal
                    id={"delete_file"}
                    show={this.state.deleteFileModalShow}
                    title="Delete file"
                    body={getMessages('delete_file')}
                    yesButton={{
                        title: "Yes",
                        onClick: () => { this.deleteFile(); },
                        spinner: this.state.deletingFile
                    }}
                    noButton={{
                        title: "Cancel",
                        onClick: () => { this.setState({ deleteFileModalShow: false }) }
                    }}
                />
            </React.Fragment>
        );
    }
}

export default JobDocuments;