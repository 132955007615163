import React, { useEffect, useState } from "react";
import {
  LocateTicketEntity,
  LocateTicketStatusEntity,
  getLocateTicketCurrentStatus,
} from "../../../shared/data/LocateTickets";
import LocateTicketItemComponent from "./LocateTicketItemComponent";
import Select from "react-select";
import { JobEntity } from "../../../shared/data/job/Job";
import { text } from "stream/consumers";

interface LocateTicketListComponentProps {
  locateTickets: LocateTicketEntity[];
  jobs?: JobEntity[] | null;
  hideOptions: boolean,
  onRefreshList?: () => void;
}

enum SortBy {
  ticketID = "ID #",
  status = "Status",
  submitDate = "Submit Date",
  expirationDate = "Expire Date",
}

const LocateTicketListComponent = ({
  locateTickets = [],
  jobs = null,
  hideOptions = false,
  onRefreshList = () => {},
}: LocateTicketListComponentProps) => {
  const [filter, setFilter] = useState<any>({
    searchText: null,
    sortBy: null,
    filterBy: null,
    filterByJob: null,
  });

  useEffect(() => {
    setFilteredLocateTickets(locateTickets);
  }, [locateTickets]);

  const [filteredLocateTickets, setFilteredLocateTickets] =
    useState<LocateTicketEntity[]>(locateTickets);

  const filterLocateTickets = () => {
    var result = [...locateTickets];

    if (filter.filterBy?.value != null) {
      result = locateTickets.filter((locateTicket) => {
        return (
          getLocateTicketCurrentStatus(locateTicket)?.type ==
          filter?.filterBy?.value
        );
      });
    }

    if (filter.filterByJob?.value != null) {
      result = locateTickets.filter((locateTicket) => {
        return locateTicket.jobID == filter.filterByJob.value;
      });
    }

    if (filter.searchText != null && filter.searchText != "") {
      result = result.filter(
        (item) =>
          item.ticketID
            ?.toLocaleLowerCase()
            .includes(filter.searchText.toLocaleLowerCase()) ||
          item.notes
            ?.toLocaleLowerCase()
            .includes(filter.searchText.toLocaleLowerCase()) ||
          item.address
            ?.toLocaleLowerCase()
            .includes(filter.searchText.toLocaleLowerCase())
      );
    }

    if (filter.sortBy != null) {
      if (filter.sortBy.value == "submitDate") {
        result = result.sort((a: LocateTicketEntity, b: LocateTicketEntity) => {
          try {
            const aLastSubmittedDate = a.status
              ?.filter((item) => item.type == "submitted")
              ?.reduce((latestItem, currentItem) => {
                return (latestItem?.createdAt ?? 0) > (currentItem?.createdAt ?? 0)
                  ? latestItem
                  : currentItem;
              }, new LocateTicketStatusEntity())
              ?.date?.toMillis();
            const bLastSubmittedDate = b.status
              ?.filter((item) => item.type == "submitted")
              ?.reduce((latestItem, currentItem) => {
                return (latestItem?.createdAt ?? 0) > (currentItem?.createdAt ?? 0)
                  ? latestItem
                  : currentItem;
              }, new LocateTicketStatusEntity())
              ?.date?.toMillis();

            if (!aLastSubmittedDate && !bLastSubmittedDate) return 0; // Neither ticket has a 'submitted' status
            if (!aLastSubmittedDate) return 1; // a is greater if b does not have a 'submitted' status
            if (!bLastSubmittedDate) return -1; // b is greater if a does not have a 'submitted' status
            return bLastSubmittedDate - aLastSubmittedDate;
          } catch (e) {
            return 0;
          }
        });
      } else if (filter.sortBy.value == "status") {
        result = result.sort((a: LocateTicketEntity, b: LocateTicketEntity) => {
          if (
            (getLocateTicketCurrentStatus(a)?.type ?? "a").toLowerCase() <
            (getLocateTicketCurrentStatus(b)?.type ?? "a").toLowerCase()
          )
            return -1;
          if (
            (getLocateTicketCurrentStatus(a)?.type ?? "a").toLowerCase() >
            (getLocateTicketCurrentStatus(b)?.type ?? "a").toLowerCase()
          )
            return 1;
          return 0;
        });
      } else if (filter.sortBy.value == "expirationDate") {
        try {
          result = result.sort(
            (a: LocateTicketEntity, b: LocateTicketEntity) => {
              if (
                (a.expirationDate?.toMillis() ?? 0) >
                (b.expirationDate?.toMillis() ?? 0)
              )
                return -1;
              if (
                (a.expirationDate?.toMillis() ?? 0) <
                (b.expirationDate?.toMillis() ?? 0)
              )
                return 1;
              return 0;
            }
          );
        } catch (e) {
          return 0;
        }
      } else {
        result = result.sort((a: any, b: any) => {
          if (a[filter.sortBy.value ?? ""] < b[filter.sortBy.value ?? ""])
            return -1;
          if (a[filter.sortBy.value ?? ""] > b[filter.sortBy.value ?? ""])
            return 1;
          return 0;
        });
      }
    }
    setFilteredLocateTickets(result);
  };

  const getStatuses = () => {
    const statuses = [];
    statuses.push({ value: "Ready to submit", label: "Ready to submit" });
    statuses.push({ value: "submitted", label: "Submitted" });
    statuses.push({ value: "approved", label: "Approved" });
    statuses.push({ value: "expired", label: "Expired" });
    return statuses;
  };

  useEffect(() => {
    console.log(filter)
    filterLocateTickets();
  }, [filter]);

  return (
    <React.Fragment>
      <div className="d-flex flex-column flex-grow-1">
        <div className="card-body p-0">
          <div
            key="task-management-filter"
            className="d-flex flex-column-fluid mt-1 mb-5"
          >
            <div className="col">
              <div className="col-form-label ml-0">
                <label>Filter by Status</label>
              </div>
              <div className="col">
                <Select
                  options={getStatuses()}
                  isSearchable={true}
                  onChange={(event: any) => {
                    setFilter((previousState: any) => {
                      return { ...previousState, filterBy: event };
                    });
                  }}
                  value={filter.filterBy}
                  defaultValue={filter.filterBy}
                  isClearable={true}
                  backspaceRemovesValue={true}
                />
              </div>
            </div>
            <div className="col">
              <div className="col-form-label ml-0">
                <label>Sort by</label>
              </div>
              <div className="col">
                <Select
                  options={Object.keys(SortBy).map((key) => {
                    const sortByKey = key as keyof typeof SortBy;
                    return { value: key, label: SortBy[sortByKey] };
                  })}
                  isSearchable={true}
                  onChange={(event: any) => {
                    setFilter((previousState: any) => {
                      return { ...previousState, sortBy: event };
                    });
                  }}
                  value={filter.sortBy}
                  defaultValue={filter.sortBy}
                  isClearable={true}
                  backspaceRemovesValue={true}
                />
              </div>
            </div>
            {(jobs?.length ?? 0 > 0) && (
              <div className="col">
                <div className="col-form-label ml-0">
                  <label>Filter by Job</label>
                </div>
                <div className="col">
                  <Select
                    options={jobs?.map((job) => {
                      return {
                        value: job.id,
                        label: `${job.jobID}(${job.title})`,
                      };
                    })}
                    isSearchable={true}
                    onChange={(event: any) => {
                      setFilter((previousState: any) => {
                        return { ...previousState, filterByJob: event };
                      });
                    }}
                    value={filter.filterByJob}
                    defaultValue={filter.filterByJob}
                    isClearable={true}
                    backspaceRemovesValue={true}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="col-lg-13 my-md-0 pb-5">
                <div className="input-icon">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                    id="kt_datatable_search_query"
                    onChange={(event) => {
                      setFilter((previousState: any) => {
                        return { ...previousState, searchText: event.target.value };
                      });
                    }}
                  />
                  <span>
                    <i className="flaticon2-search-1 text-muted" />
                  </span>
                </div>
              </div>
        </div>
        <div className="card card-custom card-shadowless rounded-top-0 bg-light">
          <div className="card-body p-0">
            <div className="row justify-content-center py-lg-10 px-lg-10">
              <div className="col-xl-12 col-xxl-12">
                {filteredLocateTickets.map((locateTicket) => {
                  return (
                    <div className="m-5">
                      <LocateTicketItemComponent
                        locateTicket={locateTicket}
                        job={jobs?.find((job) => {
                          return job.id == locateTicket.jobID;
                        })}
                        hideOptions={hideOptions}
                        jobs={jobs}
                        onDelete={() => onRefreshList()}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LocateTicketListComponent;
