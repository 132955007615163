import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import { AuthContext } from "../../../../shared/Auth";
import moment from "moment";
import { groundConditionOptions } from "../../../../shared/data/GroundConditionOptions";
import { saveSegment } from "../../../../shared/Api";
import SegmentMap from "./SegmentMap";
import {useHistory, useParams} from 'react-router-dom';
import { Timestamp } from "firebase/firestore";
import { parseFromFirebaseDate, showToast } from "../../../../shared/Util";
import { SegmentEntity } from "../../../../shared/data/taskmanagement/Segment";
import { getNextSegmentId } from "../../../../shared/api/TaskManagerApi";

const SegmentNew = (props) => {
  const context = useContext(AuthContext);
  const history = useHistory()
  const params = useParams()

  const save = async () => {
    const currentUser = context.currentUser;
    const inventoryId = currentUser.company.inventoryID;
    const result = await saveSegment(
      inventoryId,
      segmentForm.documentId,
      segmentData
    );
    if (result.status == "success") history.goBack();
    else showToast("danger", "Could not save segment");
  };

  const setSegmentDataIsValid = (data) => {
    if (
      data.startLat != null &&
      data.startLng != null &&
      data.endLat != null &&
      data.endLng != null
    ) {
      setSegmentForm((previousState) => {
        return { ...previousState, isFormValid: true };
      });
    } else {
      setSegmentForm((previousState) => {
        return { ...previousState, isFormValid: false };
      });
    }
  };

  const [segmentForm, setSegmentForm] = useState({
    documentId: props.location.state.segment?.id,
    jobStartDate: props.location.state.startDate,
    jobEndDate: props.location.state.endDate,
    isFormValid: false,
    selectedSoliCondition: groundConditionOptions[0],
  });

  const [segmentData, dispatchSegment] = useReducer((state, action) => {
    const result = {
      ...state,
      ...action,
    };
    setSegmentDataIsValid(result);
    return result;
  }, new SegmentEntity(props?.match?.params?.id??""));

  const segment = useMemo(() => props.location.state.segment, []);

  const [initialLocations, setInitialLocations] = useState(() => {
    if (
      segment?.startLat &&
      segment?.startLng &&
      segment?.endLat &&
      segment?.endLng
    )
      return [
        { lat: segment.startLat, lng: segment.startLng },
        { lat: segment.endLat, lng: segment.endLng },
      ];
    else return [];
  });

  const addSegmentLocation = (segmentLocations) => {
    const startLocation = segmentLocations[0];
    const endLocation = segmentLocations[1];
    dispatchSegment({
      startLat: startLocation ? startLocation.lat : null,
      startLng: startLocation ? startLocation.lng : null,
      endLat: endLocation ? endLocation.lat : null,
      endLng: endLocation ? endLocation.lng : null,
    });
  };

  const loadNextSegmentId = async () =>{
    const inventoryId = context.currentUser.company.inventoryID;
    const jobId = params?.id
    const nextSegmentId = await getNextSegmentId(inventoryId, jobId)

    dispatchSegment({
      segmentID: nextSegmentId.data
    });
  }

  useEffect(() => {
    const segment = props.location.state.segment;
    dispatchSegment({
      jobID : params?.id
    })
    const isEditMode = props.location.pathname.endsWith("/edit");

    if (isEditMode && segment) {
      setInitialLocations(() => {
        const locations = [
          { lat: segment.startLat, lng: segment.startLng },
          { lat: segment.endLat, lng: segment.endLng },
        ];
        return locations;
      });
      dispatchSegment({
        segmentID: segment.segmentID,
        title: segment.title ?? "",
        startDate: segment.startDate,
        startLat: segment.startLat,
        startLng: segment.startLng,
        endLat: segment.endLat,
        endLng: segment.endLng,
        description: segment.description ?? "",
        soilConditions: segment.soilConditions ?? "",
        summary: segment.summary ?? "",
      });
      setSegmentForm((previousState) => {
        const soilConditions =
          groundConditionOptions.find(
            (soilConditionOption) =>
              soilConditionOption.name === segment.soilConditions
          ) ?? groundConditionOptions[10];
        return { ...previousState, selectedSoliCondition: soilConditions };
      });
    }
    if (!segment?.segmentID) {
      loadNextSegmentId();
    }

  }, []);

  return (
    <React.Fragment>
      <div className="card">
        <div className="card-body">
          <h3 className="mb-10 font-weight-bold text-dark">Add new segment</h3>
          <div key="segmentId" className="form-group">
            <label>
              Segment Id
            </label>
            <input
              type="text"
              className="form-control"
              value={segmentData?.segmentID}
              onChange={(input) =>
                dispatchSegment({ segmentID: input.target.value })
              }
            ></input>
            <div className="form-text text-muted">Enter segment ID</div>
          </div>
          <div key="segmentName" className="form-group">
            <label>Segment Name (optional)</label>
            <input
              type="text"
              className="form-control"
              value={segmentData?.title}
              onChange={(input) =>
                dispatchSegment({ title: input.target.value })
              }
            />
            <div className="form-text text-muted">Enter sgment name</div>
          </div>
          <div key="startDate" className="form-group">
            <label>Start date</label>
            <input
              type="date"
              className="form-control"
              value={parseFromFirebaseDate(segmentData?.startDate, "YYYY-MM-DD")}
              min={parseFromFirebaseDate(segmentForm?.jobStartDate, "YYYY-MM-DD")}
              max={parseFromFirebaseDate(segmentForm?.jobEndDate, "YYYY-MM-DD")}
              onChange={(input) =>{
                dispatchSegment({ startDate: Timestamp.fromDate(moment(input.target.value).toDate()) })
              }}
            />
            <div className="form-text text-muted">Select start date</div>
          </div>
          <div key="segment-new-summary-of-measurables" className="form-group">
            <label>Summary of Measurables (optional)</label>
            <textarea
              type="textarea"
              rows={3}
              className="form-control"
              value={segmentData?.summary}
              onChange={(input) =>
                dispatchSegment({ summary: input.target.value })
              }
            />
            <div className="form-text text-muted">
              Ex: 2,000' of boring; 20,000' plowing; 10 pedestals
            </div>
          </div>
          <div key="segment-new-description" className="form-group">
            <label>Segment details (optional)</label>
            <textarea
              type="textarea"
              rows={3}
              className="form-control"
              value={segmentData?.description}
              onChange={(input) =>
                dispatchSegment({ description: input.target.value })
              }
            />
          </div>
          <div
            key="segment-new-expected-soil-conditions"
            className="form-group"
          >
            <label>Explected Soil Conditions (optional)</label>
            <select
              key="segment-new-expected-soil-conditions-select"
              className="form-control"
              onChange={(event) => {
                const selectedValue = groundConditionOptions.find(
                  (element) => element.id == event.target.value
                );

                setSegmentForm((previousState) => {
                  return {
                    ...previousState,
                    selectedSoliCondition: selectedValue,
                  };
                });
                if (selectedValue.id == 11)
                  dispatchSegment({ soilConditions: "" });
                else dispatchSegment({ soilConditions: selectedValue.name });
              }}
            >
              {groundConditionOptions.map((item, index) => {
                return (
                  <option
                    value={item.id}
                    className="form-control"
                    selected={
                      item.name == segmentForm.selectedSoliCondition.name
                    }
                  >
                    {item.name}
                  </option>
                );
              })}
              ;
            </select>
            <div
              key="segment-new-other-soil-conditions-input"
              className="form-group"
            >
              {segmentForm.selectedSoliCondition.id == 11 && (
                <>
                  <div
                    key="segment-new-expected-soil-conditions=1"
                    className="form-group"
                  >
                    <input
                      type="text"
                      value={segmentData?.soilConditions}
                      className="form-control"
                      onChange={(input) => {
                        dispatchSegment({ soilConditions: input.target.value });
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <SegmentMap
            initialLocations={initialLocations}
            centerLat={props?.location?.state?.job?.locationLat}
            centerLng={props?.location?.state?.job?.locationLong}
            onSegmentLocationsChanged={addSegmentLocation}
          />
          <div className="justify-content-between border-top mt-5 pt-10">
            <button
              key="segment-new-btn-save"
              className="btn btn-primary font-weight-bolder text-uppercase px-9 py-4 mr-2"
              disabled={!segmentForm.isFormValid}
              onClick={(event) => {
                event.preventDefault();
                save();
              }}
            >
              Save
            </button>
            <button
            onClick={() => {
              history.goBack()
            }}
            className="btn btn-secondary font-weight-bold text-uppercase px-7 py-4"
          >
            Cancel
          </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SegmentNew;
