import { db, storage } from "../../firebase"
import { isBetweenDates, isNullOrEmpty } from "../Utils"
import { CrewMemberEntity } from "../data/Crew"
import { ResponseEntity } from "../data/Response"
import { JobEntity } from "../data/job/Job"
import { FirebaseInventoriesCollections, FirebaseJobCollections, FirebaseRootCollections } from "../firebase/FirebaseSchema"
import { getCrewMembersSchedules, getCrewsSchedules, saveCrewMembersSchedules, saveCrewSchedules } from "./CrewApi"
import { getEquipmentSchedules, saveEquipmentSchedules } from "./EquipmentApi"

export const getJobReportCrewMembers = async (inventoryId: string, jobId: string) => {
    try {
        const result = await db
            .collection(FirebaseRootCollections.INVENTORIES)
            .doc(inventoryId)
            .collection(FirebaseInventoriesCollections.JOBS)
            .doc(jobId)
            .collection(FirebaseJobCollections.CREW_MEMBERS)
            .get()

        const crewMembers = result.docs.map((doc: any) => {
            return { ...doc.data(), id: doc.id }
        })

        return new ResponseEntity<CrewMemberEntity[]>(crewMembers, "")
    } catch (error: any) {
        return new ResponseEntity<CrewMemberEntity[]>(null, error)
    }
}

export const saveJobReportCrewMembers = async (inventoryId: string, jobId: string, crewMember: CrewMemberEntity) => {
    try {
        await db.collection(FirebaseRootCollections.INVENTORIES)
            .doc(inventoryId)
            .collection(FirebaseInventoriesCollections.JOBS)
            .doc(jobId)
            .collection(FirebaseJobCollections.CREW_MEMBERS)
            .add({ ...crewMember })

        return new ResponseEntity<CrewMemberEntity>(crewMember, "")
    } catch (error: any) {
        return new ResponseEntity<CrewMemberEntity>(crewMember, error)
    }
}

export const updateJobReservations = async (inventoryId: string, job: JobEntity) => {
    if (!isNullOrEmpty(job.id)) {
        const crews = (await getCrewsSchedules(inventoryId, job.id ?? "")).data?.filter((crew) => {
            return !isBetweenDates(job.startDate?.toDate(), job.endDate?.toDate(), crew.startDate?.toDate()) ||
                !isBetweenDates(job.startDate?.toDate(), job.endDate?.toDate(), crew.endDate?.toDate())
        })
            ?.map((crew) => { return { ...crew, startDate: job.startDate, endDate: job.endDate } }) ?? []

        const crewMembers = (await getCrewMembersSchedules(inventoryId, job.id ?? "")).data?.filter((crewMember) => {
            return !isBetweenDates(job.startDate?.toDate(), job.endDate?.toDate(), crewMember.startDate?.toDate()) ||
                !isBetweenDates(job.startDate?.toDate(), job.endDate?.toDate(), crewMember.endDate?.toDate())
        })
            ?.map((crewMember) => { return { ...crewMember, startDate: job.startDate, endDate: job.endDate } }) ?? []


        const equipment = (await getEquipmentSchedules(inventoryId, job.id ?? "")).data?.filter((equipment) => {
            return !isBetweenDates(job.startDate?.toDate(), job.endDate?.toDate(), equipment.startDate?.toDate()) ||
                !isBetweenDates(job.startDate?.toDate(), job.endDate?.toDate(), equipment.endDate?.toDate())
        })
            ?.map((equipment) => { return { ...equipment, startDate: job.startDate, endDate: job.endDate } }) ?? []


        const allRequests = [
            ...[crews?.map((crew) => { return saveCrewSchedules(inventoryId, crew) }) ?? []],
            ...[crewMembers?.map((crewMember) => { return saveCrewMembersSchedules(inventoryId, crewMember) }) ?? []],
            ...[equipment?.map((equipment) => { return saveEquipmentSchedules(inventoryId, equipment) }) ?? []]
        ]

        Promise.all(allRequests)
    }
}

export const getJobDocuments = async (inventoryId: string, jobId: string, companyId:string) => {
    try {

        const foldersResult = await db
            .collection(FirebaseRootCollections.INVENTORIES)
            .doc(inventoryId)
            .collection(FirebaseInventoriesCollections.JOBS)
            .doc(jobId)
            .collection(FirebaseJobCollections.FOLDERS)
            .get()

        const filesResult = await db
            .collection(FirebaseRootCollections.INVENTORIES)
            .doc(inventoryId)
            .collection(FirebaseInventoriesCollections.JOBS)
            .doc(jobId)
            .collection(FirebaseJobCollections.FILES)
            .get()

            var folders : any = foldersResult.docs.map(folder => { return { ...folder.data(), id: folder.id }; });
            var files: any = filesResult.docs.map(file => { return { ...file.data(), id: file.id }; });
    
            try {
              await Promise.all(
                files.map(async (element:any) => {
                  try {
                    let storageRef = storage.ref(
                      `/${companyId}/Files/${jobId}/${
                        element.uploadedAt?.seconds ??
                        element.createdAt?.seconds ??
                        ""
                      }/${element.filename ?? element.fileName ?? ""}`
                    );
                    let url = await storageRef.getDownloadURL();
                    element.fileLink = url;
                  } catch (error) {
                    console.error(error);
                    console.log("Fetching again")
                    try {
                      let jobStorageRef = storage.ref(
                        `/${companyId}/job/${jobId}/${
                          element.uploadedAt?.seconds ??
                          element.createdAt?.seconds ??
                          ""
                        }/${element.filename ?? element.fileName ?? ""}`
                      );
                      let url = await jobStorageRef.getDownloadURL();
                      element.fileLink = url;
                    } catch (error) {
                      element.fileLink = null;
                    }
                  }
                })
              );
            } catch (error) {
                console.error(error);
            }

            const folderMap = new Map();

            // Initialize folders with their basic structure
            folders.forEach((folder:any) => {
               folderMap.set(folder.id, { ...folder, subfolders: [], files: [], type: 'folder' });
             });
     
             const rootItems:any = [];
     
             // Organize folders into hierarchy
             folders.forEach((folder:any) => {
               if (folder.categoryId == null) {
                 rootItems.push(folderMap.get(folder.id));
               } else {
                 const parent = folderMap.get(folder.categoryId);
                 if (parent) {
                   parent.subfolders.push(folderMap.get(folder.id));
                 }
               }
             });
     
             // Assign files to the correct folders
             files.forEach((file:any) => {
               if (file.categoryID == null) {
                 rootItems.push(file);
               } else {
                 const parent = folderMap.get(file.categoryID);
                 if (parent) {
                   parent.files.push(file);
                 }
               }
             });

             return {
                status: 'success',
                data: rootItems
            }

    } catch (exception: any) {
        console.error(exception)

        return {
            status: 'error',
            data: exception
        }
    }
}