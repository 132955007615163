import { ResponseEntity } from "../data/Response";

export const getAddressesFromCoordinates = async (lat: number, lng: number) => {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_MAP_KEY}`;
    try {
        const response = await fetch(url);
        const data = await response.json();
        return new ResponseEntity<GoogleMapAddressResponse>(data)
    } catch (error: any) {
        return new ResponseEntity<GoogleMapAddressResponse>(null, error)
    }
};

export interface GoogleMapAddressResponse {
    plus_code?: string | null,
    status: string,
    results?: GoogleMapAddressResult[] | null
}

export interface GoogleMapAddressResult {
    formatted_address?: string | null
}