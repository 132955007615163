import React, { Component } from 'react';
import { getBore, getCrewSchedulesForJob } from '../../../shared/Api';
import { AuthContext } from "../../../shared/Auth";
import Loader from '../../../components/Layout/Loader/Loader';
import * as moment from 'moment';
import { Link } from 'react-router-dom';
import { buildBoreLogFileEntityPath, buildBoreLogFilesPath } from '../../../shared/data/bore/BoreEntity';
import { getFileData } from '../../../shared/api/FilesApi';

class BoreLogs extends Component {
    static contextType = AuthContext;
    user = this.context.currentUser.userProfile;
    canEdit = this.user.permissions !== 'crewmember';

    state = {
        bore: null,
        loading: true,
        lastDate: null,
        logs: [],
        date: null,
        assignedCrewmeberIds: null
    }

    componentDidMount = async () => {
        this.setState({ loading: true });

        await this.loadBore();
        await this.loadAssignedCrewmembers();

        this.setState({ loading: false });
    }

    loadBore = async () => {
        const currentUser = this.context.currentUser;
        const inventoryId = currentUser.company.inventoryID;
        const companyId = currentUser.userProfile.companyID;
        const boreId = this.props.match.params.boreId;
        const date = this.props.match.params.date;

        const boreResult = await getBore(inventoryId, boreId);
        const bore = boreResult.data;
        let days = [];

        bore.boreLogs.forEach(boreLog => {
            const date = boreLog.strippedDate;
            let day = days.find(x => x.date === date);
            if (!day) {
                day = {
                    date: date,
                    logs: [boreLog],
                    totalDistance: boreLog.distance,
                    totalPitch: boreLog.pitch,
                    totalDepth: boreLog.depth
                }

                days.push(day);
            } else {
                day.logs.push(boreLog);
                day.totalDistance += boreLog.distance;
                day.totalPitch += boreLog.pitch;
                day.totalDepth += boreLog.depth;
            }
        });
        bore.days = days.sort(function(a,b){
            return new Date(b.date) - new Date(a.date);
        });

        const day = days.find(x => moment(x.date).unix() == date);

        if(!day)
            return;

        const logs = day.logs.sort(function(a,b){
            return b.boreLogNumber - a.boreLogNumber;
        });


            // revers order of logs so that total distance can be calculated
            let distanceBored = 0
            logs?.reverse().forEach((log)=>{
              distanceBored += log.distance
              log.distanceBored  = distanceBored
            });
            // array is reversed back in original sorting position
            logs?.reverse()

        await Promise.all(logs.map( async (log)=>{
            const boreLogFiles = await getFileData(
                buildBoreLogFileEntityPath(inventoryId, boreId, log.id),
                buildBoreLogFilesPath(companyId, log.id)
              );
              log.files = boreLogFiles.data
        }))

        const last = logs.length > 0 ? logs[0] : null;
        const lastDate = last ? last.date : null;
        const createdBy = last.createdBy;

        this.setState({ bore: bore, logs: logs, date: day.date, lastDate: lastDate, createdBy: createdBy });
    }

    loadAssignedCrewmembers = async () => {
        const currentUser = this.context.currentUser;
        const inventoryId = currentUser.company.inventoryID;
        const jobId = this.props.match.params.id;

        const schedulesResult = await getCrewSchedulesForJob(inventoryId, jobId);
        const assignedCrewmeberIds = schedulesResult.data.map(x => x.crewMemberID);

        this.setState({ assignedCrewmeberIds: assignedCrewmeberIds });
    }

    canAddBorLog = () => {
        if(this.user.permissions !== 'crewmember')
            return true;

        return this.state.assignedCrewmeberIds && this.state.assignedCrewmeberIds.includes(this.user.crewmemberID);
    }

    getDistance = () => {
        let distance = 0;
        this.state.logs.forEach(x => {
            distance += x.distance;
        });

        return distance;
    }

    render() {
        if (this.state.loading)
            return <Loader />

        const distance = this.getDistance();

        return (
            <div className="card">
                <div className="card-body">
                    <div className="row my-10 px-8 my-lg-15 px-lg-10">
                        <div className="col-xl-12">
                            <div className="mb-12">
                                <h3 className="mb-10 font-weight-bold text-dark d-inline">Bore Log - <span className="font-size-h5">{this.state.date}</span></h3>

                                {this.canAddBorLog() &&
                                <Link to={"/jobs/" + this.props.match.params.id + "/bores/" + this.props.match.params.boreId + "/borelogs/new"}
                                    className='btn btn-primary font-weight-bold mr-4 float-right'>Add New Bore Log</Link>}
                            </div>
                            <div className="bg-light rounded card-rounded flex-grow-1 pt-10 pr-10 pl-10 pb-4">
                                <div className="row m-0 mb-12">
                                    <div className="col px-8 py-6">
                                        <div className="font-size-h6 text-muted font-weight-bold mb-2">as of</div>
                                        <div className="font-size-h6 font-weight-bolder">{moment(this.state.lastDate.toDate()).format("MM/DD/YY")}</div>
                                        <div className="font-size-h6 font-weight-bolder">{moment(this.state.lastDate.toDate()).format("hh:mm a")}</div>
                                    </div>
                                    <div className="col px-8 py-6">
                                        <div className="font-size-h6 font-weight-bold mb-4">{distance} ft</div>
                                        <div className="font-size-h6 text-muted font-weight-bolder">Total</div>
                                        <div className="font-size-h6 text-muted font-weight-bolder">Distance</div>
                                    </div>
                                    <div className="col px-8 py-6">
                                        <div className="font-size-h6 font-weight-bold mb-4">{this.state.bore.boreProgress ? (this.state.bore.boreLength - this.state.bore.boreProgress).toFixed(2) : this.state.bore.boreLength}{" "} ft</div>
                                        <div className="font-size-h6 text-muted font-weight-bolder">Distance</div>
                                        <div className="font-size-h6 text-muted font-weight-bolder">Remaining</div>
                                    </div>
                                    {this.state.createdBy &&
                                    <div className="col px-8 py-6">
                                        <div className="font-size-h6 font-weight-bold mb-4">{this.state.createdBy}</div>
                                        <div className="font-size-h6 text-muted font-weight-bolder">Created By</div>
                                    </div>}
                                </div>
                            </div>                            
                            <div className="card-spacer rounded card-rounded flex-grow-1">
                                {this.state.logs.map((item, index) => (
                                    <div key = {"brs-lgs" + index}className="row m-0 border rounded mt-10">
                                        <div className="col px-8 py-6">
                                            <div className="font-size-h6 mb-4">{moment(item.date?.toDate())?.format("hh:mma")}</div>
                                            <div className="font-size-h6 text-muted font-weight-bolder">#{('000' + item.boreLogNumber).substr(-3)}</div>
                                        </div>
                                        <div className="col px-8 py-6">
                                            <div className="font-size-h6 font-weight-bold mb-4">{item.distance} ft</div>
                                            <div className="font-size-h6 text-muted font-weight-bolder">Distance</div>
                                        </div>
                                        <div className="col px-8 py-6">
                                            <div className="font-size-h6 font-weight-bold mb-4">{item.distanceBored} ft</div>
                                            <div className="font-size-h6 text-muted font-weight-bolder">Distance Bored</div>
                                        </div>
                                        <div className="col px-8 py-6">
                                            <div className="font-size-h6 font-weight-bold mb-4">{item.depth} in</div>
                                            <div className="font-size-h6 text-muted font-weight-bolder">Depth</div>
                                        </div>
                                        <div className="col px-8 py-6">
                                            <div className="font-size-h6 font-weight-bold mb-4">{item.pitch} %</div>
                                            <div className="font-size-h6 text-muted font-weight-bolder">Pitch</div>
                                        </div>
                                        <div className="col px-8 py-6">
                                            {(item.imageTimeStamp || item.image?.length > 0 || item.files?.length > 0)&&
                                                <div className="font-size-h6 font-weight-bold mb-4 mt-4"><i class="fa far fa-images"></i></div> 
                                            }   
                                        </div>
                                        <div className="col-1 px-8 py-6">
                                            <div className="dropdown dropdown-inline ml-2" data-toggle="tooltip" title="Quick actions" data-placement="left">
                                                <a className="btn btn-hover-light-primary btn-sm btn-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <i className="ki ki-bold-more-hor" />
                                                </a>
                                                <div className="dropdown-menu p-0 m-0 dropdown-menu-md dropdown-menu-right">
                                                    <ul className="navi navi-hover">
                                                        <Link to={"/jobs/" + this.props.match.params.id + "/bores/" + this.props.match.params.boreId + "/borelogs/" + this.props.match.params.date + "/" + item.id + "/details" } className="navi-item">
                                                            <a className="navi-link">
                                                                <span className="navi-text">
                                                                    View Bore Entry Details
                                                                </span>
                                                            </a>
                                                        </Link>
                                                        {this.canEdit &&
                                                        <Link to={"/jobs/" + this.props.match.params.id + "/bores/" + this.props.match.params.boreId + "/borelogs/" + this.props.match.params.date + "/" + item.id + "/edit" } className="navi-item">
                                                            <a className="navi-link">
                                                                <span className="navi-text">
                                                                    Edit Bore Entry
                                                                </span>
                                                            </a>
                                                        </Link>}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default BoreLogs;