import React, { Component } from 'react';

import { AuthContext } from "../../../shared/Auth";
import Loader from '../../../components/Layout/Loader/Loader';
import { getBoreLog } from "../../../shared/Api";
import { getBore } from "../../../shared/Api";
import * as moment from 'moment';
import MultipleItems from '../../../components/Carousels/MultipleItems';
import { getFileData } from '../../../shared/api/FilesApi';
import { buildBoreLogFileEntityPath, buildBoreLogFilesPath } from '../../../shared/data/bore/BoreEntity';
import Viewer from 'react-viewer';

class BoreLogsDetails extends Component {
  static contextType = AuthContext;
  user = this.context.currentUser.userProfile;
  canEdit = this.user.permissions !== 'crewmember';

  state = {
    boreLog: null
  }

  componentDidMount = async () => {
    await this.loadBoreLog();
  }

  loadBoreLog = async () => {
    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;
    const companyId = currentUser.userProfile.companyID
    const boreId = this.props.match.params.boreId;
    const boreLogId = this.props.match.params.boreLogId;

    const boreLogResult = await getBoreLog(inventoryId, boreId, boreLogId, companyId, true);
    const boreResult = await getBore(inventoryId, boreId);
    const boreLogFiles = await getFileData(
      buildBoreLogFileEntityPath(inventoryId, boreId, boreLogId),
      buildBoreLogFilesPath(companyId, boreLogId)
    )
    this.setState({ boreLog: boreLogResult.data, bore: boreResult.data, boreLogFiles:boreLogFiles.data });
  }

   getImagesForViewer = () => {
    return this.state.boreLogFiles.map((file)=>{
      return {src: file.url??""}
    })
  };

  handleDownload = async (url, index) =>{
    const fileName = this.state.boreLogFiles.find((file)=>file.url==url.src)?.fileName ?? `${this.state.bore.boreName}-log-${this.state.boreLog.boreLogNumber}-image.jpeg`
    try {
      const response  = await fetch(url.src);
      const blob = await response.blob();
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = fileName
      link.click();
      URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  }

   getUrlFileName = (url) => {
    const regExp = new RegExp(".+(\\/|%2F)(.+)\\?.+");
    const match = regExp.exec(url);
    if (match) {
      return match[2];
    }
    return "Unknown";
  };

  render() {
    if (this.state.boreLog) {
      return (
        <React.Fragment>
          <div className="card card-custom">
            <div className="card-header h-auto py-4">
              <div className="card-title">
                <h3 className="card-label">Bore Log details</h3>
              </div>
              <div className="card-toolbar">
                {this.canEdit &&
                <a onClick={() => { this.props.history.push('/jobs/' + this.props.match.params.id + '/bores/' + this.props.match.params.boreId + '/borelogs/' + this.props.match.params.date + "/" + this.props.match.params.boreLogId + '/edit') }} className="btn btn-success btn-sm font-weight-bold mr-4">
                  <i className="flaticon-edit" />Edit</a>}
              </div>
            </div>
            <div className="card-body py-4">
            {this.state.boreLog.edited ? 
              <div className="form-group row my-2a">
                <label className="col-4 col-form-label">edited entry</label>
              </div>
               : ""}
            {/* {this.state.boreLog.image ?
                <div className="form-group row my-2">
                  <label className="col-4 col-form-label"></label>
                  <div className="col-8">
                    <div className="image-input image-input-outline" id="kt_profile_avatar" style={{ backgroundImage: 'url(assets/media/users/blank.png)' }}>
                      <div className="image-input-wrapper" style={{ backgroundImage: 'url(' + this.state.boreLog.image + ')', backgroundSize: 'contain, cover', backgroundPosition: 'center', width: '160px', height: '160px' }} />
                    </div>
                  </div>
                </div> : null} */}
            <div className="form-group row my-2">
                <label className="col-4 col-form-label">Entry No:</label>
                <div className="col-8">
                  <span className="form-control-plaintext font-weight-bolder">#{('000' + this.state.boreLog.boreLogNumber).substr(-3)}</span>
                </div>
              </div>
              <div className="form-group row my-2">
                <label className="col-4 col-form-label">Entry Date:</label>
                <div className="col-8">
                <span className="form-control-plaintext font-weight-bolder">{moment(this.state.boreLog?.date?.toDate())?.format("M/DD/YY")}</span>
                </div>
              </div>
              <div className="form-group row my-2">
                <label className="col-4 col-form-label">Entry Time:</label>
                <div className="col-8">
                <span className="form-control-plaintext font-weight-bolder">{moment(this.state.boreLog?.date?.toDate())?.format("hh:mma")}</span>
                </div>
              </div>
              <div className="form-group row my-2">
                <label className="col-4 col-form-label">Created By:</label>
                <div className="col-8">
                <span className="form-control-plaintext font-weight-bolder">{this.state.boreLog.createdBy}</span>
                </div>
              </div>
              <div className="form-group row my-2">
                <label className="col-4 col-form-label">Distance Drilled:</label>
                <div className="col-8">
                <span className="form-control-plaintext font-weight-bolder">{this.state.boreLog.distance} ft</span>
                </div>
              </div>
              <div className="form-group row my-2">
                <label className="col-4 col-form-label">Total Distance Drilled:</label>
                <div className="col-8">
                <span className="form-control-plaintext font-weight-bolder">{this.state.bore.boreProgress} ft</span>
                </div>
              </div>
              <div className="form-group row my-2">
                <label className="col-4 col-form-label">Soil conditions:</label>
                <div className="col-8">
                <span className="form-control-plaintext font-weight-bolder">{this.state.boreLog.soilConditions}</span>
                </div>
              </div>
              <div className="form-group row my-2">
                <label className="col-4 col-form-label">Bore Location:</label>
                <div className="col-8">
                <span className="form-control-plaintext font-weight-bolder">{this.state.boreLog.lat}, {this.state.boreLog.long}</span>
                </div>
              </div>
              <div className="form-group row my-2">
                <label className="col-4 col-form-label">Depth:</label>
                <div className="col-8">
                <span className="form-control-plaintext font-weight-bolder">{this.state.boreLog.depth} in</span>
                </div>
              </div>
              <div className="form-group row my-2">
                <label className="col-4 col-form-label">Pitch:</label>
                <div className="col-8">
                <span className="form-control-plaintext font-weight-bolder">{this.state.boreLog.pitch} %</span>
                </div>
              </div>
              {this.state.boreLog.distanceEOP && <div className="form-group row my-2">
                <label className="col-4 col-form-label">Distance from EOP:</label>
                <div className="col-8">
                <span className="form-control-plaintext font-weight-bolder">{this.state.boreLog.distanceEOP} ft</span>
                </div>
              </div>}
              <div className="form-group row my-2">
                <label className="col-4 col-form-label">Notes:</label>
                <div className="col-8">
                <span className="form-control-plaintext font-weight-bolder">{this.state.boreLog.notes}</span>
                </div>
              </div>
              <MultipleItems
                slidesToShow={9}
                laptop={7}
                tablet={5}
                mobile={2}
                removePhoto={false}
                images={this.state.boreLogFiles?.map((boreLogFiles) => {
                  return {
                    image: boreLogFiles.url,
                    title: boreLogFiles.fileName,
                    name: boreLogFiles.name,
                    createdAt: moment(
                      boreLogFiles.createdAt?.toDate()
                    ).format("MM/DD/YY, hh:mma"),
                    uploadedBy: boreLogFiles.createdBy
                  };
                })}
                setSelectedImage={(index) => {
                  const selectedFile = this.state.boreLogFiles[index];
                  if (this.getUrlFileName(selectedFile.url??"").endsWith("med")) {
                    this.setState({showImageViewer:true})
                  } else {
                    window.open(
                      selectedFile.url??"",
                      "_blank",
                      "noopener,noreferrer"
                    );
                  }
                }}
              />
              <Viewer
              images={this.getImagesForViewer()}
              visible={this.state?.showImageViewer ?? false}
              onClose={() => {
                this.setState({showImageViewer:false})
              }}
              activeIndex={0}
              downloadable={true}
              customToolbar={(toolbars) => {
                return toolbars.map((toolbar) => {
                  if (toolbar.key === "download") {
                    return {
                      ...toolbar,
                      onClick: this.handleDownload,
                    };
                  }
                  return toolbar;
                });
              }}
            />
            </div>
            <div className="card-footer">
            </div>
            <div className="my-4">
              
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      return <Loader />
    }
  }
}

export default BoreLogsDetails;