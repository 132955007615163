import { element } from 'prop-types';
import app, { db, storage } from '../firebase';
import { saveTask } from './api/TaskManagerApi';
import { TaskEntity, TaskType } from './data/taskmanagement/Task';

export const addJob = async (inventoryId, job) => {
    try {
        const doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('jobs')
            .add(job);

        return {
            status: 'success',
            data: doc.id
        }
    } catch (err) {
        return {
            status: 'error',
            data: err
        }
    }
}

export const addExampleJob = async (inventoryId, job) => {
    try {
        const doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('jobs')
            .doc('TBvTCRzefgnU1uLhrrDJ')
            .set(job);

        return {
            status: 'success',
            data: doc.id
        }
    } catch (err) {
        return {
            status: 'error',
            data: err
        }
    }
}

export const updateJob = async (inventoryId, jobId, job) => {
    try {
        const doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('jobs')
            .doc(jobId)
            .update(job);
        return {
            status: 'success',
            data: jobId
        }
    } catch (err) {
        console.log(err)
        return {
            status: 'error',
            data: err
        }
    }
}

export const addCrewSchedule = async (inventoryId, crewSchedule) => {
    try {
        const doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('crew_schedules')
            .add(crewSchedule);

        return {
            status: 'success',
            data: doc.id
        }
    } catch (err) {
        return {
            status: 'error',
            data: err
        }
    }
}

export const deleteCrewSchedule = async (inventoryId, crewScheduleId) => {
    try {
        const doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('crew_schedules')
            .doc(crewScheduleId)
            .delete()

        return {
            status: 'success'
        }
    } catch (err) {
        console.log(err);
        return {
            status: 'error',
            data: err
        }
    }
}

export const addReservation = async (inventoryId, reservation) => {
    try {
        const doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('reservations')
            .add(reservation);

        return {
            status: 'success',
            data: doc.id
        }
    } catch (err) {
        return {
            status: 'error',
            data: err
        }
    }
}

export const updateReservation = async (inventoryId, reservationId, updatePartial) => {
    try {
        const doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('reservations')
            .doc(reservationId)
            .update(updatePartial);

        return {
            status: 'success'
        }
    } catch (err) {
        return {
            status: 'error',
            data: err
        }
    }
}

export const addCustomChecklist = async (inventoryId, customChecklist) => {
    try {
        const doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('custom_checklists')
            .add(customChecklist);

        return {
            status: 'success',
            data: doc.id
        }
    } catch (err) {
        return {
            status: 'error',
            data: err
        }
    }
}

export const getJob = async (inventoryId, jobId) => {
    try {
        const doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('jobs')
            .doc(jobId).
            get();

        return {
            status: 'success',
            data: { ...doc.data(), id: doc.id }
        }
    } catch (err) {
        return {
            status: 'error',
            data: err
        }
    }
}

export const getJobs = async (inventoryId) => {
    try {
        const doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('jobs')
            .get();

        return {
            status: 'success',
            data: doc.docs.map(x => { return { ...x.data(), id: x.id }; })
        }
    } catch (err) {
        return {
            status: 'error',
            data: err
        }
    }
}

export const getClients = async (inventoryId) => {
    try {
        const doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('clients')
            .get();

        return {
            status: 'success',
            data: doc.docs.map(x => { return { ...x.data(), id: x.id }; })
        }
    } catch (err) {
        return {
            status: 'error',
            data: err
        }
    }
}

export const addClient = async (inventoryId, client) => {
    try {
        const doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('clients')
            .add(client);

        return {
            status: 'success',
            data: doc.id
        }
    } catch (err) {
        return {
            status: 'error',
            data: err
        }
    }
}

export const addExampleClient = async (inventoryId, client) => {
    try {
        const doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('clients')
            .doc('4Cs05TP6vZlDKPzFnZTa')
            .set(client);

        return {
            status: 'success',
            data: doc.id
        }
    } catch (err) {
        return {
            status: 'error',
            data: err
        }
    }
}

export const getClient = async (inventoryId, clientId) => {
    try {
        const doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('clients')
            .doc(clientId)
            .get();

            return {
                status: 'success',
                data: { ...doc.data(), id: doc.id }
            }
    } catch (err) {
        return {
            status: 'error',
            data: err
        }
    }
}

export const deleteClient = async (inventoryId, clientId) => {
    try {
        const doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('clients')
            .doc(clientId)
            .delete()

        return {
            status: 'success'
        }
    } catch (err) {
        console.log(err);
        return {
            status: 'error',
            data: err
        }
    }
}

export const updateClient = async (inventoryId, clientId, client) => {
    try {
        const doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('clients')
            .doc(clientId)
            .update(client);

        return {
            status: 'success'
        }
    } catch (err) {
        console.log(err);
        return {
            status: 'error',
            data: err
        }
    }
}

export const loadEquipmentImages = async (companyId, equipment) => {
    const data = await Promise.all(equipment.map(async (item) => {
        if (item.hasProfileImage && item.imageTimeStamp) {
            try {
                const storageRef = storage.ref('/' + companyId + '/equipment/' + item.id + '/' + item.imageTimeStamp.seconds + '/');
                const url = await storageRef.child('med').getDownloadURL();
                item.image = url;
            } catch (err) {

            }
        }

        return item;
    }));

    return data;
} 

export const getExampleEquipment = async () => {
    try {
        let doc = await db.collection('settings')
            .doc("example")
            .collection('equipment')
            .doc("frWyXdd26lYNx2HiT5oj")
            .get()
        
        return {
            status: 'success',
            data: {...doc.data(),}
        }
    } catch (err) {
        return {
            status: 'error',
            data: err
        }
    }
}

export const getEquipment = async (inventoryId, companyId, includeImages, includeCrews) => {
    try {
        const doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('equipment')
            .get();

        let data = includeImages ?
            await Promise.all(doc.docs.map(async (doc) => {
                let dat = doc.data();
                dat.id = doc.id;

                if (dat.hasProfileImage && dat.imageTimeStamp) {
                    try {
                        const storageRef = storage.ref('/' + companyId + '/equipment/' + dat.id + '/' + dat.imageTimeStamp.seconds + '/');
                        const url = await storageRef.child('med').getDownloadURL();
                        dat.image = url;
                    } catch (err) {

                    }
                }

                return dat;
            }))
            :
            doc.docs.map(x => { return { ...x.data(), id: x.id }; });

            if(includeCrews) {
                let crews = await db.collection('inventories')
                                    .doc(inventoryId)
                                    .collection('crews')
                                    .get();
    
                crews = crews.docs.map(x => { return { ...x.data(), id: x.id }; });
    
                data.forEach(element => {
                    if(element.crewID) {
                        const crew = crews.find(x => element.crewID === x.id);
                        element.crew = crew;
                    }                
                });
            }

        return {
            status: 'success',
            data: data
        }
    } catch (err) {
        return {
            status: 'error',
            data: err
        }
    }
}



export const getCrewmembers = async (inventoryId, companyId, includeImages, includeCrews) => {
    try {
        const doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('crew')
            .get();

        let data = includeImages ?
            await Promise.all(doc.docs.map(async (doc) => {
                let dat = doc.data();
                dat.id = doc.id;

                if (dat.hasProfileImage && dat.imageTimeStamp) {
                    try {
                        const storageRef = storage.ref('/' + companyId + '/crewMember/' + dat.id + '/' + dat.imageTimeStamp.seconds + '/');
                        const url = await storageRef.child('med').getDownloadURL();
                        dat.image = url;
                    } catch (err) {

                    }
                }

                return dat;
            }))
            :
            doc.docs.map(x => { return { ...x.data(), id: x.id }; });

        if(includeCrews) {
            let crews = await db.collection('inventories')
                                .doc(inventoryId)
                                .collection('crews')
                                .get();

            crews = crews.docs.map(x => { return { ...x.data(), id: x.id }; });

            data.forEach(element => {
                if(element.crewID) {
                    const crew = crews.find(x => element.crewID === x.id);
                    element.crew = crew;
                }                
            });
        }

        return {
            status: 'success',
            data: data
        }
    } catch (err) {
        return {
            status: 'error',
            data: err
        }
    }
}

export const addCrewmember = async (inventoryId, companyId, crewmember) => {
    try {
        const doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('crew')
            .add(crewmember);

        return {
            status: 'success',
            data: doc.id
        }
    } catch (err) {
        return {
            status: 'error',
            data: err
        }
    }
}

export const addExampleCrewmember = async (inventoryId, companyId, crewmember) => {
    try {
        const doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('crew')
            .doc('1OlimyEZpvgrlcB7M9fT')
            .set(crewmember);

        return {
            status: 'success',
            data: doc.id
        }
    } catch (err) {
        return {
            status: 'error',
            data: err
        }
    }
}

export const getCrewmember = async (inventoryId, crewmemberId, companyId, includeImage, includeCrew) => {
    try {
        const doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('crew')
            .doc(crewmemberId)
            .get();

        let dat = doc.data();

        if (includeImage && dat.imageTimeStamp) {
          const storageRef = storage.ref(
            "/" +
              companyId +
              "/crewMember/" +
              doc.id +
              "/" +
              dat.imageTimeStamp.seconds +
              "/"
          );
          try {
            const url = await storageRef.child("med").getDownloadURL();
            dat.image = url;
          } catch (error) {
            console.log(error);
          }
        }

        if(includeCrew && dat.crewID) {
            const crew = await db.collection('inventories')
            .doc(inventoryId)
            .collection('crews')
            .doc(dat.crewID)
            .get();

            dat.crew = { ...crew.data(), id: crew.id }
        }
        return {
            status: 'success',
            data: { ...dat, id: doc.id }
        }
    } catch (err) {
        return {
            status: 'error',
            data: err
        }
    }
}

export const getUserByCrewmemberId = async (crewmemberId) => {
    try {
        const doc = await db.collection('users')
            .where('crewmemberID', '==', crewmemberId)
            .get();

        let dats = doc.docs.map(x => { return { ...x.data(), id: x.id }; });
        let dat = dats[0];

        return {
            status: 'success',
            data: dat
        }
    } catch (err) {
        return {
            status: 'error',
            data: err
        }
    }
}

export const getCrewmemberByUserId = async (inventoryId, userId) => {
    try {
        const doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('crew')
            .where('userID', '==', userId)
            .get();

        let dats = doc.docs.map(x => { return { ...x.data(), id: x.id }; });
        let dat = dats[0];

        return {
            status: 'success',
            data: dat
        }
    } catch (err) {
        return {
            status: 'error',
            data: err
        }
    }
}

export const updateCrewmember = async (inventoryId, crewmemberId, crewmember) => {
    try {
        const doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('crew')
            .doc(crewmemberId)
            .update(crewmember);

        return {
            status: 'success'
        }
    } catch (err) {
        console.log(err);
        return {
            status: 'error',
            data: err
        }
    }
}

export const updateUser = async (userId, user) => {
    try {
        const doc = await db.collection('users')
            .doc(userId)
            .update(user);

        return {
            status: 'success'
        }
    } catch (err) {
        console.log(err);
        return {
            status: 'error',
            data: err
        }
    }
}

export const addEquipment = async (inventoryId, companyId, equipment) => {
    try {
        const doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('equipment')
            .add(equipment);

        return {
            status: 'success',
            data: doc.id
        }
    } catch (err) {
        return {
            status: 'error',
            data: err
        }
    }
}

export const addExampleEquipment = async (inventoryId, companyId, equipment) => {
    try {
        const doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('equipment')
            .doc('frWyXdd26lYNx2HiT5oj')
            .set(equipment);

        return {
            status: 'success',
            data: doc.id
        }
    } catch (err) {
        return {
            status: 'error',
            data: err
        }
    }
}

export const getSingleEquipment = async (inventoryId, equipmentId, companyId, includeImage, includeCrew) => {
    try {
        const doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('equipment')
            .doc(equipmentId).
            get();

        let dat = doc.data();

        const images = [];
        try{
        if (dat.hasProfileImage && dat.imageTimeStamp && includeImage) {
            const storageRef = storage.ref('/' + companyId + '/equipment/' + doc.id + '/' + dat.imageTimeStamp.seconds + '/');
            const url = await storageRef.child('med').getDownloadURL();
            dat.image = url;
            images.push(url);
        }
     }catch(error){

        }

        try{
        if (dat.photos && dat.photos.length > 0 && includeImage) {
            await Promise.all(dat.photos.map(async (x) => {            
                const storageRef = storage.ref('/' + companyId + '/equipment/' + doc.id + '/' + x.seconds + '/');
                const url = await storageRef.child('med').getDownloadURL();
                images.push(url);
            })); 
        }
    }catch(error){

    }

        dat.images = images;

        if(includeCrew && dat.crewID) {
            const crew = await db.collection('inventories')
            .doc(inventoryId)
            .collection('crews')
            .doc(dat.crewID)
            .get();

            dat.crew = { ...crew.data(), id: crew.id }
        }

        return {
            status: 'success',
            data: { ...dat, id: doc.id }
        }
    } catch (err) {
        return {
            status: 'error',
            data: err
        }
    }
}

export const updateEquipment = async (inventoryId, equipmentId, equipment) => {
    try {
        const doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('equipment')
            .doc(equipmentId)
            .update(equipment);

        return {
            status: 'success'
        }
    } catch (err) {
        console.log(err);
        return {
            status: 'error',
            data: err
        }
    }
}

export const uploadImage = async (
  companyId,
  type,
  docId,
  timestamp,
  imageValue
) => {
  try {
    const storageRef = storage.ref(
      companyId + "/" + type + "/" + docId + "/" + timestamp.seconds + "/med"
    );
    const result = await storageRef.put(imageValue);
  } catch (exception) {
    console.log(exception);
  }
};

export const getReservation = async (inventoryId, reservationId) => {
    try {
        const doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('reservations')
            .doc(reservationId)
            .get();

            return {
                status: 'success',
                data: { ...doc.data(), id: doc.id }
            }
    } catch (err) {
        return {
            status: 'error',
            data: err
        }
    }
}

export const deleteReservation = async (inventoryId, reservationId) => {
    try {
        const doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('reservations')
            .doc(reservationId)
            .delete()

        return {
            status: 'success'
        }
    } catch (err) {
        console.log(err);
        return {
            status: 'error',
            data: err
        }
    }
}

export const getReservations = async (inventoryId) => {
    try {
        let doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('reservations')
            .get();

        return {
            status: 'success',
            data: doc.docs.map(x => { return { ...x.data(), id: x.id }; })
        }
    } catch (err) {
        return {
            status: 'error',
            data: err
        }
    }
}

export const getReservationsForAJob = async (inventoryId, jobId) => {
    try {
        let doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('reservations')
            .where('jobID', '==', jobId)
            .get();

        return {
            status: 'success',
            data: doc.docs.map(x => { return { ...x.data(), id: x.id }; })
        }
    } catch (err) {
        return {
            status: 'error',
            data: err
        }
    }
}

export const getReservationsForEquipment = async (inventoryId, equipmentId) => {
    try {
        let doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('reservations')
            .where('equipmentID', '==', equipmentId)
            .get();

        return {
            status: 'success',
            data: doc.docs.map(x => { return { ...x.data(), id: x.id }; })
        }
    } catch (err) {
        return {
            status: 'error',
            data: err
        }
    }
}

export const getCrewSchedules = async (inventoryId) => {
    try {
        let doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('crew_schedules')
            .get();

        return {
            status: 'success',
            data: doc.docs.map(x => { return { ...x.data(), id: x.id }; })
        }
    } catch (err) {
        return {
            status: 'error',
            data: err
        }
    }
}

export const getCrewSchedulesForCrew = async (inventoryId, crewmwmberId) => {
    try {
        let doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('crew_schedules')
            .where("crewMemberID", '==', crewmwmberId)
            .get();


        let arr = doc.docs.map(x => { return { ...x.data(), id: x.id }; });
        arr = [...new Map(arr.map(item =>[item['jobID'], item])).values()];

        return {
            status: 'success',
            data: arr
        }
    } catch (err) {
        return {
            status: 'error',
            data: err
        }
    }
}

export const getCrewSchedulesForJob = async (inventoryId, jobId) => {
    try {
        let doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('crew_schedules')
            .where("jobID", '==', jobId)
            .get();

        return {
            status: 'success',
            data: doc.docs.map(x => { return { ...x.data(), id: x.id }; })
        }
    } catch (err) {
        return {
            status: 'error',
            data: err
        }
    }
}

export const getCustomChecklists = async (inventoryId, jobId, companyId, includeImages) => {
    try {
        let doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('custom_checklists')
            .where('jobID', '==', jobId)
            .get();

        let data = includeImages ?
            await Promise.all(doc.docs.map(async (doc) => {
                let dat = doc.data();
                dat.id = doc.id;

                if (dat.imageTimeStamp) {
                    try {
                        const storageRef = storage.ref('/' + companyId + '/customChecklist/' + dat.id + '/' + dat.imageTimeStamp.seconds + '/');
                        const url = await storageRef.child('med').getDownloadURL();
                        dat.image = url;
                    } catch (err) {

                    }
                }

                return dat;
            }))
            :
            doc.docs.map(x => { return { ...x.data(), id: x.id }; });

        return {
            status: 'success',
            data: data
        }
    } catch (err) {
        return {
            status: 'error',
            data: err
        }
    }
}

export const getBoreImagesForJob = async (inventoryId, companyId, jobId) => {
    let bores = await db.collection('inventories')
                    .doc(inventoryId)
                    .collection('bores')
                    .where("jobID", '==', jobId)
                    .get();

    const list = bores.docs.map(x => { return { ...x.data(), id: x.id }; });

    const response = [];
    let boreIndex = 1;

    await Promise.all(list.map(async (doc) => {            
        let boreLogs = await db.collection('inventories')
                                .doc(inventoryId)
                                .collection('bores')
                                .doc(doc.id)
                                .collection('boreLogs')
                                .get();

        doc.boreLogs = boreLogs.docs.map(x => { return { ...x.data(), id: x.id }; });
        doc.images = [];
        let index = 1;

        await Promise.all(doc.boreLogs.map(async (element) => {
            if(element.imageTimeStamp) {
                try {
                    const storageRef = storage.ref('/' + companyId + '/boreLog/' + element.id + '/' + element.imageTimeStamp.seconds + '/');
                    const url = await storageRef.child('med').getDownloadURL();
                    doc.images.push({ fileLink: url, name: index, type: 'photo', createdAt: element.imageTimeStamp });
                    index++;
                } catch (err) {
            
                }
            }
        }));

        if(doc.images && doc.images.length !== 0) {
            response.push({
                name: doc.boreName ? doc.boreName : "Bore " + boreIndex,
                images: doc.images,
                id: doc.id,
                number: doc.boreNumber,
                display: "Bore #" + ('000' + doc.boreNumber).substr(-3)
            });
        }

        boreIndex++;
    }));

    return response;
}

export const addMaterial = async (inventoryId, companyId, equipment) => {
    try {
        const doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('custom_checklists')
            .add(equipment);

        return {
            status: 'success',
            data: doc.id
        }
    } catch (err) {
        console.log(err);
        return {
            status: 'error',
            data: err
        }
    }
}

export const updateMaterial = async (inventoryId, materialId, material) => {
    try {
        const doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('custom_checklists')
            .doc(materialId)
            .update(material);

        return {
            status: 'success'
        }
    } catch (err) {
        console.log(err);
        return {
            status: 'error',
            data: err
        }
    }
}

export const getMaterial = async (inventoryId, materialId) => {
    try {
        const doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('custom_checklists')
            .doc(materialId)
            .get();

        return {
            status: 'success',
            data: { ...doc.data(), id: doc.id }
        }
    } catch (err) {
        return {
            status: 'error',
            data: err
        }
    }
}

export const getBores = async (inventoryId, jobId) => {
    try {
        let doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('bores')
            .where("jobID", "==", jobId)
            .get();

        return {
            status: 'success',
            data: doc.docs.map(x => { return { ...x.data(), id: x.id }; })
        }
    } catch (err) {
        return {
            status: 'error',
            data: err
        }
    }
}

export const addBore = async (inventoryId, bore, createTask) => {
  try {
    const {id, ...boreWithoutId} = bore
    const doc = await db
      .collection("inventories")
      .doc(inventoryId)
      .collection("bores")
      .add(boreWithoutId);


    if (createTask) {
      const task = new TaskEntity(
        bore.jobID,
        bore?.segmentID,
        bore?.lat,
        bore?.long
      );
      task.groundConditions = bore.soilConditions
      task.title = bore.boreName;
      task.address = bore.address
      task.taskType = TaskType.borePilot;
      task.priority = "low";
      task.taskStatus = "open";
      task.startDate = bore.startDate;
      task.endDate = bore.endDate;
      task.taskID = Date.now().toString();
      task.expectedFootage = Number(bore.boreLength);
      task.estimatedDuration = 10;
      task.boreID = doc.id;
      task.notes = bore.notes;
      task.crews = [bore.crewID];
      task.products = bore.products;

      const taskResult = await saveTask(inventoryId, task);
      const boreWithTaskId = { ...bore };
      boreWithTaskId.taskID = taskResult.data.id;
      const result = await updateBore(inventoryId, doc.id, boreWithTaskId);
    }

    return {
      status: "success",
      data: doc.id,
    };
  } catch (err) {
    console.log(err);
    return {
      status: "error",
      data: err,
    };
  }
};

export const deleteBore = async (inventoryId, boreId) => {
    try {
        const doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('bores')
            .doc(boreId)
            .delete()

        return {
            status: 'success'
        }
    } catch (err) {
        console.log(err);
        return {
            status: 'error',
            data: err
        }
    }
}

export const getBore = async (inventoryId, boreId) => {
    try {
        const doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('bores')
            .doc(boreId)
            .get();

        let logs = await db.collection('inventories')
            .doc(inventoryId)
            .collection('bores')
            .doc(boreId)
            .collection('boreLogs')
            .get();

        const boreLogs = logs.docs.map(x => { return {...x.data(), id: x.id }});

            let crewMembers = await getCrewmembers(inventoryId, null, false, false);
            boreLogs.forEach(boreLog => {
                if(boreLog.userID) {
                    const crewMember = crewMembers.data.find(x => x.id === boreLog.userID);
                    if(crewMember) {
                        boreLog.createdBy = crewMember.firstName + " " + crewMember.lastName;
                    }
                }
            });

        return {
            status: 'success',
            data: { ...doc.data(), id: doc.id, boreLogs: boreLogs }
        }
    } catch (err) {
        return {
            status: 'error',
            data: err
        }
    }
}

export const updateBore = async (inventoryId, boreId, bore) => {
    try {
        const doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('bores')
            .doc(boreId)
            .update(bore);

        return {
            status: 'success'
        }
    } catch (err) {
        console.log(err);
        return {
            status: 'error',
            data: err
        }
    }
}

export const addBoreLog = async (inventoryId, boreId, boreLog) => {
    try {
        const doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('bores')
            .doc(boreId)
            .collection('boreLogs')
            .add(boreLog);

            await synchronizeBore(inventoryId, boreId);

        return {
            status: 'success',
            data: doc.id
        }
    } catch (err) {
        console.log(err);
        return {
            status: 'error',
            data: err
        }
    }
}

export const getBoreLog = async (inventoryId, boreId, boreLogId, companyId, includeImage) => {
    try {
        const doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('bores')
            .doc(boreId)
            .collection('boreLogs')
            .doc(boreLogId)
            .get();

        let dat = doc.data();

        try{
        if (dat.imageTimeStamp && includeImage) {
            const storageRef = storage.ref('/' + companyId + '/boreLog/' + doc.id + '/' + dat.imageTimeStamp.seconds + '/');
            const url = await storageRef.child('med').getDownloadURL();
            dat.image = url;
        }

     }catch(error){

     }

        if(dat.userID) {
            const crewmember = await getCrewmember(inventoryId, dat.userID, null, false, false);
            dat.createdBy = crewmember.data.firstName + " " + crewmember.data.lastName;
        }

        return {
            status: 'success',
            data: { ...dat, id: doc.id }
        }
    } catch (err) {
        return {
            status: 'error',
            data: err
        }
    }
}

export const updateBoreLog = async (inventoryId, boreId, boreLogId, boreLog) => {
    try {
        const doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('bores')
            .doc(boreId)
            .collection('boreLogs')
            .doc(boreLogId)
            .update(boreLog);

        await synchronizeBore(inventoryId, boreId);

        return {
            status: 'success'
        }
    } catch (err) {
        console.log(err);
        return {
            status: 'error',
            data: err
        }
    }
}

const synchronizeBore = async (inventoryId, boreId) => {
    const bore = await getBore(inventoryId, boreId);
    let distance = 0;

    bore.data.boreLogs.forEach(element => {
        distance += element.distance;
    });

    updateBore(inventoryId, boreId, { boreProgress: distance });
}

// export const getDocuments = async (inventoryId, jobId, companyId) => {
//     try {
//         let folders = await db.collection('inventories')
//             .doc(inventoryId)
//             .collection('jobs')
//             .doc(jobId)
//             .collection('folders')
//             .get();

//         let files = await db.collection('inventories')
//             .doc(inventoryId)
//             .collection('jobs')
//             .doc(jobId)
//             .collection('files')
//             .get();


//         folders = folders.docs.map(x => { return { ...x.data(), id: x.id }; });
//         files = files.docs.map(x => { return { ...x.data(), id: x.id }; });

//         try {
//           await Promise.all(
//             files.map(async (element) => {
//               try {
//                 let storageRef = storage.ref(
//                   `/${companyId}/Files/${jobId}/${
//                     element.uploadedAt?.seconds ??
//                     element.createdAt?.seconds ??
//                     ""
//                   }/${element.filename ?? element.fileName ?? ""}`
//                 );
//                 let url = await storageRef.getDownloadURL();
//                 element.fileLink = url;
//               } catch (error) {
//                 console.error(error);
//                 console.log("Fetching again")
//                 try {
//                   let jobStorageRef = storage.ref(
//                     `/${companyId}/job/${jobId}/${
//                       element.uploadedAt?.seconds ??
//                       element.createdAt?.seconds ??
//                       ""
//                     }/${element.filename ?? element.fileName ?? ""}`
//                   );
//                   let url = await jobStorageRef.getDownloadURL();
//                   element.fileLink = url;
//                 } catch (error) {
//                   element.fileLink = null;
//                 }
//               }
//             })
//           );
//         } catch (error) {
//             console.error(error);
//         }

//         const folderMap = new Map();

//          // Initialize folders with their basic structure
//          folders.forEach((folder) => {
//             folderMap.set(folder.id, { ...folder, subfolders: [], files: [], type: 'folder' });
//           });
  
//           const rootItems = [];
  
//           // Organize folders into hierarchy
//           folders.forEach((folder) => {
//             if (folder.categoryId == null) {
//               rootItems.push(folderMap.get(folder.id));
//             } else {
//               const parent = folderMap.get(folder.categoryId);
//               if (parent) {
//                 parent.subfolders.push(folderMap.get(folder.id));
//               }
//             }
//           });
  
//           // Assign files to the correct folders
//           files.forEach((file) => {
//             if (file.categoryID == null) {
//               rootItems.push(file);
//             } else {
//               const parent = folderMap.get(file.categoryID);
//               if (parent) {
//                 parent.files.push(file);
//               }
//             }
//           });


//         return {
//             status: 'success',
//             data: rootItems
//         }
//     } catch (err) {
//         return {
//             status: 'error',
//             data: err
//         }
//     }
// }

export const addFolder = async (inventoryId, jobId, folderName, categoryId) => {
    try {
        const doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('jobs')
            .doc(jobId)
            .collection('folders')
            .add({ name: folderName, createdAt: new Date(), categoryId: categoryId });
        return {
            status: 'success',
            data: doc.id
        }
    } catch (err) {
        console.log(err);
        return {
            status: 'error',
            data: err
        }
    }
}

export const updateFolder = async (inventoryId, jobId, folderId, folderName) => {
    try {
        const doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('jobs')
            .doc(jobId)
            .collection('folders')
            .doc(folderId)
            .update({ name: folderName });
        return {
            status: 'success',
            data: doc.id
        }
    } catch (err) {
        console.log(err);
        return {
            status: 'error',
            data: err
        }
    }
}

export const deleteFolder = async (inventoryId, jobId, folderId) => {
    try {
        const doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('jobs')
            .doc(jobId)
            .collection("folders")
            .doc(folderId)
            .delete()

        return {
            status: 'success'
        }
    } catch (err) {
        console.log(err);
        return {
            status: 'error',
            data: err
        }
    }
}

export const addFile = async (inventoryId, jobId, file) => {
    try {
        const doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('jobs')
            .doc(jobId)
            .collection('files')
            .add(file);
        return {
            status: 'success',
            data: doc.id
        }
    } catch (err) {
        console.log(err);
        return {
            status: 'error',
            data: err
        }
    }
}

export const updateFile = async (inventoryId, jobId, fileId, file) => {
    try {
        const doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('jobs')
            .doc(jobId)
            .collection('files')
            .doc(fileId)
            .update(file);
        return {
            status: 'success',
            data: doc.id
        }
    } catch (err) {
        console.log(err);
        return {
            status: 'error',
            data: err
        }
    }
}

export const getFile = async (inventoryId, jobId, fileId) => {
    try {
        const doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('jobs')
            .doc(jobId)
            .collection('files')
            .doc(fileId)
            .get();

        return {
            status: 'success',
            data: { ...doc.data(), id: doc.id }
        }
    } catch (err) {
        return {
            status: 'error',
            data: err
        }
    }
}

export const deleteFile = async (inventoryId, jobId, fileId) => {
    try {
        const doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('jobs')
            .doc(jobId)
            .collection("files")
            .doc(fileId)
            .delete()

        return {
            status: 'success'
        }
    } catch (err) {
        console.log(err);
        return {
            status: 'error',
            data: err
        }
    }
}

export const uploadFile = async (companyId, type, docId, timestamp, fileName, fileValue) => {
    const storageRef = storage.ref(companyId + '/' + type + '/' + docId + '/' + timestamp.seconds + '/' + fileName);
    await storageRef.put(fileValue);
}

export const getExampleCrew = async () => {
    try {
        let doc = await db.collection('settings')
            .doc("example")
            .collection('crews')
            .doc("LtqxqopPZUnj4hKqzeQw")
            .get()
        return {
            status: 'success',
            data: {...doc.data()}
        }
    } catch (err) {
        return {
            status: 'error',
            data: err
        }
    }
}

export const getExampleCrewmember = async () => {
    try {
        let doc = await db.collection('settings')
            .doc("example")
            .collection('crewmembers')
            .doc("1OlimyEZpvgrlcB7M9fT")
            .get()
        
        return {
            status: 'success',
            data:  {...doc.data()}
        }
    } catch (err) {
        return {
            status: 'error',
            data: err
        }
    }
}

export const getExampleClient = async () => {
    try {
        let doc = await db.collection('settings')
            .doc("example")
            .collection('clients')
            .doc("4Cs05TP6vZlDKPzFnZTa")
            .get()

        return {
            status: 'success',
            data: {...doc.data()}
        }
    } catch (err) {
        return {
            status: 'error',
            data: err
        }
    }
}

export const getExampleContact = async () => {
    try {
        let doc = await db.collection('settings')
            .doc("example")
            .collection('clients')
            .doc("4Cs05TP6vZlDKPzFnZTa")
            .collection("contacts")
            .doc("t1ZqESIqdZhkgGG19C7u")
            .get()

        return {
            status: 'success',
            data: {...doc.data()}
        }
    } catch (err) {
        return {
            status: 'error',
            data: err
        }
    }
}

export const getExampleJob = async () => {
    try {
        let doc = await db.collection('settings')
            .doc("example")
            .collection('jobs')
            .doc("TBvTCRzefgnU1uLhrrDJ")
            .get()

        return {
            status: 'success',
            data: {...doc.data()}
        }
    } catch (err) {
        return {
            status: 'error',
            data: err
        }
    }
}

export const getExampleJobFolder = async () => {
    
        try {
            let doc = await db.collection('settings')
                .doc("example")
                .collection('jobs')
                .doc("TBvTCRzefgnU1uLhrrDJ")
                .collection("folders")
                .get()
    
            return {
                status: 'success',
                data: doc.docs.map(x => { return { ...x.data(), folderID: x.id }; })
            }
        } catch (err) {
            return {
                status: 'error',
                data: err
            }
        }
}

export const getCrews = async (inventoryId) => {
    try {
        let doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('crews')
            .get();

        return {
            status: 'success',
            data: doc.docs.map(x => { return { ...x.data(), id: x.id }; })
        }
    } catch (err) {
        return {
            status: 'error',
            data: err
        }
    }
}

export const getCrew = async (inventoryId, crewId) => {
    try {
        const doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('crews')
            .doc(crewId)
            .get();

        return {
            status: 'success',
            data: { ...doc.data(), id: doc.id }
        }
    } catch (err) {
        return {
            status: 'error',
            data: err
        }
    }
}

export const deleteCrew = async (inventoryId, crewId) => {
    try {
        const doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('crews')
            .doc(crewId)
            .delete()

        return {
            status: 'success'
        }
    } catch (err) {
        console.log(err);
        return {
            status: 'error',
            data: err
        }
    }
}

export const addCrew = async (inventoryId, crew) => {
    try {
        const doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('crews')
            .add(crew);
        return {
            status: 'success',
            data: doc.id
        }
    } catch (err) {
        console.log(err);
        return {
            status: 'error',
            data: err
        }
    }
}

export const addExampleCrew = async (inventoryId, crew) => {
    try {
        const doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('crews')
            .doc('LtqxqopPZUnj4hKqzeQw')
            .set(crew);
        return {
            status: 'success',
            data: doc.id
        }
    } catch (err) {
        console.log(err);
        return {
            status: 'error',
            data: err
        }
    }
}

export const updateCrew = async (inventoryId, crewId, crew) => {
    try {
        const doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('crews')
            .doc(crewId)
            .update(crew);
        return {
            status: 'success',
            data: doc.id
        }
    } catch (err) {
        console.log(err);
        return {
            status: 'error',
            data: err
        }
    }
}

export const getCompanyOwner = async (companyId) => {
    try {
        const doc = await db.collection('users')
            .where('companyID', '==', companyId)
            .get();

        let users = doc.docs.map(x => { return { ...x.data(), id: x.id }; });
        const owner = users.find(x => x.permissions === 'owner');

        return owner;
    } catch (err) {
        return {
            status: 'error',
            data: err
        }
    }
}

export const getCrewAppointments = async (inventoryId) => {
    try {
        let doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('crews_appointments')
            .get();

        return {
            status: 'success',
            data: doc.docs.map(x => { return { ...x.data(), id: x.id }; })
        }
    } catch (err) {
        return {
            status: 'error',
            data: err
        }
    }
}

export const getCrewAppointmentsByJob = async (inventoryId, jobId) => {
    try {
        let doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('crews_appointments')
            .where('jobID', '==', jobId)
            .get();

        return {
            status: 'success',
            data: doc.docs.map(x => { return { ...x.data(), id: x.id }; })
        }
    } catch (err) {
        return {
            status: 'error',
            data: err
        }
    }
}

export const addCrewAppoitment = async (inventoryId, crewAppoitment) => {
    try {
        const doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('crews_appointments')
            .add(crewAppoitment);
        return {
            status: 'success',
            data: doc.id
        }
    } catch (err) {
        console.log(err);
        return {
            status: 'error',
            data: err
        }
    }
}

export const deleteCrewAppoitment = async (inventoryId, appoitmentId) => {
    try {
        const doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('crews_appointments')
            .doc(appoitmentId)
            .delete()

        return {
            status: 'success'
        }
    } catch (err) {
        console.log(err);
        return {
            status: 'error',
            data: err
        }
    }
}

export const addInvite = async (invite) => {
    try {
        const doc = await db.collection('invitations')
            .add(invite);
        return {
            status: 'success',
            data: doc.id
        }
    } catch (err) {
        console.log(err);
        return {
            status: 'error',
            data: err
        }
    }
}

export const inviteExists = async (code) => {
    try {
        let doc = await db.collection('invitations')
        .where('inviteCode', '==', code)
        .get();

        return doc.docs.length > 0;
    } catch (err) {
        return {
            status: 'error',
            data: err
        }
    }
}

export const getInvite = async(code) => {
    try {
        let doc = await db.collection('invitations')
        .where('inviteCode', '==', code)
        .get();

        if(doc.docs.length === 0)
            return null;

        return {
            status: 'success',
            data: { ...doc.docs[0].data(), id: doc.docs[0].id }
        }
    } catch (err) {
        return {
            status: 'error',
            data: err
        }
    }
}

export const getPresetFolders = async (inventoryId) => {
    try {
        let doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('preset_folders')
            .get();

        return {
            status: 'success',
            data: doc.docs.map(x => { return { ...x.data(), id: x.id }; })
        }
    } catch (err) {
        return {
            status: 'error',
            data: err
        }
    }
}

export const addPresetFolder = async (inventoryId, folder) => {
    try {
        const doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('preset_folders')
            .add(folder);
        return {
            status: 'success',
            data: doc.id
        }
    } catch (err) {
        console.log(err);
        return {
            status: 'error',
            data: err
        }
    }
}

export const deletePresetFolder = async (inventoryId, folderId) => {
    try {
        const doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('preset_folders')
            .doc(folderId)
            .delete()

        return {
            status: 'success'
        }
    } catch (err) {
        console.log(err);
        return {
            status: 'error',
            data: err
        }
    }
}

export const addJobPhoto = async (inventoryId, jobId, photo) => {
    try {
        const doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('jobs')
            .doc(jobId)
            .collection("photos")
            .add(photo);
        return {
            status: 'success',
            data: doc.id
        }
    } catch (err) {
        console.log(err);
        return {
            status: 'error',
            data: err
        }
    }
}

export const getJobPhoto = async(inventoryId, jobId, photoId) => {
    try {
        const doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('jobs')
            .doc(jobId)
            .collection("photos")
            .doc(photoId)
            .get();

        return {
            status: 'success',
            data: { ...doc.data(), id: doc.id }
        }
    } catch (err) {
        return {
            status: 'error',
            data: err
        }
    }
}

export const removeJobPhoto = async (inventoryId, jobId, photoId) => {
    try {
        const doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('jobs')
            .doc(jobId)
            .collection("photos")
            .doc(photoId)
            .delete()

        return {
            status: 'success'
        }
    } catch (err) {
        console.log(err);
        return {
            status: 'error',
            data: err
        }
    }
}

export const getJobPhotos = async (inventoryId, jobId, companyId) => {
    try {
        const job = await getJob(inventoryId, jobId);

        let photoFolders = await db.collection('inventories')
            .doc(inventoryId)
            .collection('jobs')
            .doc(jobId)
            .collection('photoFolders')
            .get();

        let jobPhotos = await db.collection('inventories')
            .doc(inventoryId)
            .collection('jobs')
            .doc(jobId)
            .collection('photos')
            .get();

        photoFolders = photoFolders.docs.map(x => { return { ...x.data(), id: x.id }; });
        jobPhotos = jobPhotos.docs.map(x => { return { ...x.data(), id: x.id }; });
        
        if(job.data.photos) {
            for(let i = 0; i < job.data.photos.length; i++) {
                jobPhotos.push({ createdAt: job.data.photos[i], photoType: 'old', oldPhotoIndex: i });
            }
        }

        await Promise.all(jobPhotos.map(async(element) => {
            try {
                let storageRef = storage.ref('/' + companyId + '/job/' + jobId + '/' + element.createdAt.seconds + '/med');
                let url = await storageRef.getDownloadURL();
                element.fileLink = url;
            } catch(error) {
                element.fileLink = null;
            }
        }));

        const documents = photoFolders.filter(x => !x.categoryID);

        documents.forEach(document => {
            document.type = 'folder';
            document.level = 1;
            document.images = jobPhotos.filter(x => x.categoryID === document.id).map(x => { return {...x, type: 'photo'}});
        });

        jobPhotos.filter(x => !x.categoryID).forEach(element => {
            element.type = 'photo';
            documents.push(element);
        });

        return {
            status: 'success',
            data: documents
        }
    } catch (err) {
        return {
            status: 'error',
            data: err
        }
    }
}

export const getContacts = async (inventoryId, clientId) => {
    try {
        let doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('clients')
            .doc(clientId)
            .collection('contacts')
            .get();

        return {
            status: 'success',
            data: doc.docs.map(x => { return { ...x.data(), id: x.id }; })
        }
    } catch (err) {
        return {
            status: 'error',
            data: err
        }
    }
}

export const getContact = async (inventoryId, clientId, contactId) => {
    try {
        let doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('clients')
            .doc(clientId)
            .collection('contacts')
            .doc(contactId)
            .get();

        return {
            status: 'success',
            data: { ...doc.data(), id: doc.id }
        }
    } catch (err) {
        return {
            status: 'error',
            data: err
        }
    }
}

export const addContact = async (inventoryId, clientId, contact) => {
    try {
        const doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('clients')
            .doc(clientId)
            .collection("contacts")
            .add(contact);
        return {
            status: 'success',
            data: doc.id
        }
    } catch (err) {
        console.log(err);
        return {
            status: 'error',
            data: err
        }
    }
}

export const addExampleContact = async (inventoryId, clientId, contact) => {

    try {
        const doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('clients')
            .doc(clientId)
            .collection('contacts')
            .doc('t1ZqESIqdZhkgGG19C7u')
            .set(contact);
        return {
            status: 'success',
            data: doc.id
        }
    } catch (err) {
        console.log(err);
        return {
            status: 'error',
            data: err
        }
    }
}



export const updateContact = async (inventoryId, clientId, contactId, data) => {
    try {
        const doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('clients')
            .doc(clientId)
            .collection("contacts")
            .doc(contactId)
            .update(data);
        return {
            status: 'success',
            data: doc.id
        }
    } catch (err) {
        console.log(err);
        return {
            status: 'error',
            data: err
        }
    }
}

export const removeContact = async (inventoryId, clientId, contactId) => {
    try {
        const doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('clients')
            .doc(clientId)
            .collection("contacts")
            .doc(contactId)
            .delete()

        return {
            status: 'success'
        }
    } catch (err) {
        console.log(err);
        return {
            status: 'error',
            data: err
        }
    }
}

export const addJobFolder = async (inventoryId, jobId, folder) => {
    try {
        const doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('jobs')
            .doc(jobId)
            .collection("photoFolders")
            .add(folder);
        return {
            status: 'success',
            data: doc.id
        }
    } catch (err) {
        console.log(err);
        return {
            status: 'error',
            data: err
        }
    }
}

export const addExampleJobFolder = async (inventoryId, folder) => {
    
    try {
        const doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('jobs')
            .doc('TBvTCRzefgnU1uLhrrDJ')
            .collection("photoFolders")
            .doc(folder.folderID)
            .set(folder);
        return {
            status: 'success',
            data: doc.id
        }
    } catch (err) {
        console.log(err);
        return {
            status: 'error',
            data: err
        }
    }
}

export const updatePhoto = async (inventoryId, jobId, photoId, categoryID) => {
    try {
        const doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('jobs')
            .doc(jobId)
            .collection('photos')
            .doc(photoId)
            .update({categoryID:categoryID});
        return {
            status: 'success',
            data: photoId
        }
    } catch (err) {
        console.log(err);
        return {
            status: 'error',
            data: err
        }
    }
}

export const updatePhotoFolder = async (inventoryId, jobId, folderId, name) => {
    try {
        const doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('jobs')
            .doc(jobId)
            .collection("photoFolders")
            .doc(folderId)
            .update({ name: name });
        return {
            status: 'success',
            data: doc.id
        }
    } catch (err) {
        console.log(err);
        return {
            status: 'error',
            data: err
        }
    }
}

export const removePhotoFolder = async (inventoryId, jobId, folderId) => {
    try {
        const doc = await db.collection('inventories')
            .doc(inventoryId)
            .collection('jobs')
            .doc(jobId)
            .collection("photoFolders")
            .doc(folderId)
            .delete()

        return {
            status: 'success'
        }
    } catch (err) {
        console.log(err);
        return {
            status: 'error',
            data: err
        }
    }
}

export const getCategories = async () => {
    try {
        let doc = await db.collection('settings')
            .doc("equipment")
            .collection('category')
            .get();

        return {
            status: 'success',
            data: doc.docs.map(x => { return { ...x.data(), id: x.id }; })
        }
    } catch (err) {
        return {
            status: 'error',
            data: err
        }
    }
}

export const getPrivateSoilConditions = async (companyID) => {
    try {
        let doc = await db.collection('soilConditions')
            .where('companyId', '==', companyID)
            .get();

        return {
            status: 'success',
            data: doc.docs.map(x => { return { ...x.data(), documentId: x.id }; })
        }
    } catch (err) {
        return {
            status: 'error',
            data: err
        }
    }
}

export const getPublicSoilConditions = async (companyID) => {
    try {
        let doc = await db.collection('soilConditions')
            .where('publicEntry', '==', true)
            .get();

        return {
            status: 'success',
            data: doc.docs.map(x => { return { ...x.data(), documentId: x.id }; })
        }
    } catch (err) {
        return {
            status: 'error',
            data: err
        }
    }
}

export const getSoilCondition = async (conditionID) => {
    try {
        let doc = await db.collection('soilConditions')
            .doc(conditionID)
            .get()
            
        return {
            status: 'success',
            data: { ...doc.data(), id: doc.id }
        }
    } catch (err) {
        return {
            status: 'error',
            data: err
        }
    }
}

export const getSoilConditionsForBore = async (boreID) => {
    try {
        let doc = await db.collection('soilConditions')
            .where('boreId', '==', boreID)
            .get();

        return {
            status: 'success',
            data: doc.docs.map(x => { return { ...x.data(), id: x.id }; })
        }
    } catch (err) {
        return {
            status: 'error',
            data: err
        }
    }
}

export const updateSoilCondition = async (id, model) => {
    try {
        const doc = await db.collection('soilConditions')
            .doc(id)
            .update(model);

        return {
            status: 'success'
        }
    } catch (err) {
        console.log(err);
        return {
            status: 'error',
            data: err
        }
    }
}

export const deleteSoilCondition = async (id) => {
    try {
        const doc = await db.collection('soilConditions')
            .doc(id)
            .delete();

        return {
            status: 'success'
        }
    } catch (err) {
        console.log(err);
        return {
            status: 'error',
            data: err
        }
    }
}

export const addSoilCondition = async (model) => {
    try {
        const doc = await db.collection('soilConditions')
            .add(model)

        return {
            status: 'success',
            data: doc.id
        }
    } catch (err) {
        console.log(err);
        return {
            status: 'error',
            data: err
        }
    }
}

export const getNotifications = async () => {
    try {
        let doc = await db.collection('notifications')
            .get();

        return {
            status: 'success',
            data: doc.docs.map(x => { return { ...x.data(), id: x.id }; })
        }
    } catch (err) {
        return {
            status: 'error',
            data: err
        }
    }
}

export const updateNotification = async (id, model) => {
    try {
        let doc = await db.collection('notifications')
            .doc(id)
            .update(model);

        return {
            status: 'success',
        }
    } catch (err) {
        return {
            status: 'error',
            data: err
        }
    }
}

export const getNotification = async (id) => {
    try {
        let doc = await db.collection('notifications')
            .doc(id)
            .get();

        return {
            status: 'success',
            data: { ...doc.data(), id: doc.id }
        }
    } catch (err) {
        return {
            status: 'error',
            data: err
        }
    }
}



export const deleteNotification = async (id) => {
    try {
        let doc = await db.collection('notifications')
            .doc(id)
            .delete();

        return {
            status: 'success',
        }
    } catch (err) {
        return {
            status: 'error',
            data: err
        }
    }
}

export const addNotification = async (model) => {
    try {
        const doc = await db.collection('notifications')
            .add(model)

        return {
            status: 'success',
            data: doc.id
        }
    } catch (err) {
        console.log(err);
        return {
            status: 'error',
            data: err
        }
    }
}

export const getSegments = async (inventoryId, jobId) =>{
    try {
        let doc = await db.collection('inventories')
        .doc(inventoryId)
        .collection('segments')
        .where("jobID", "==", jobId)
        .get();

        return {
            status:'success',
            data: doc.docs.map(result=>{return {...result.data(), id: result.id};})
        }
        
    } catch (error) {
        return {
            status:'error',
            data: error
        }
    }
}

export const saveSegment = async (inventoryId, documentId, segment) => {
  try {
    let result;
    const collection = db
      .collection("inventories")
      .doc(inventoryId)
      .collection("segments");

    if (!segment.segmentID) segment.segmentID = Date.now().toString();

    const { id, ...segmentWithoutId } = segment;

    if (documentId) {
      result = await collection.doc(documentId).update(segmentWithoutId);
    } else {
      result = await collection.add(segmentWithoutId);
    }
    return {
      status: "success",
      data: result?.id ?? documentId,
    };
  } catch (err) {
    console.log(err);
    return {
      status: "error",
      data: err,
    };
  }
};

export const updateJobDuration = async (
  inventoryId,
  jobId,
  startDate,
  endDate
) => {
  try {
    const doc = await db
      .collection("inventories")
      .doc(inventoryId)
      .collection("jobs")
      .doc(jobId)
      .update({ startDate: startDate, endDate: endDate });
    return {
      status: "succes",
      data: doc,
    };
  } catch (error) {
    return {
      status: "error",
      data: error,
    };
  }
};
    
export const getTasks = async (inventoryId, jobId) => {
  try {
    let doc = await db
      .collection("inventories")
      .doc(inventoryId)
      .collection("tasks")
      .where("jobID", "==", jobId)
      .get();

    return {
      status: "success",
      data: doc.docs.map((result) => {
        return { ...result.data(), id: result.id };
      }),
    };
  } catch (error) {
    return {
      status: "error",
      data: error,
    };
  }
};

export const getSegmentsWithTasks = async (inventoryId, jobId) => {
  const segments = await getSegments(inventoryId, jobId);
  const tasks = await getTasks(inventoryId, jobId);

  if (segments.status != "error" && tasks.status != "error") {
    const singleTasks = tasks.data.filter(
      (result) => result.segmentID == "" || result.segmentID == null
    );
    return {
      status: "success",
      data: {
        segments: segments.data,
        tasks: singleTasks,
      },
    };
  } else {
    return {
      status: "error",
      data: "Error loading segments with tasks",
    };
  }
};

