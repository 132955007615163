import React, { useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import Geosuggest from "react-geosuggest";
import { Location } from "../../../shared/data/Location";
import {
  calculateDistance,
  calculateMiddlePoint,
  isWithinRadius,
} from "../../../shared/Util";
import EQMarker from "../../../components/GoogleMap/EQMarker";
import { SegmentEntity } from "../../../shared/data/taskmanagement/Segment";
import { getFirstElement } from "../../../shared/Utils";
import { getAddressesFromCoordinates } from "../../../shared/api/GoogleMapsApi";

interface BoresNewMapProps {
  segments: SegmentEntity[];
  boreCurrentLocation?: Location | null;
  onLocationAdded?: (
    location?: Location | null,
    segmentId?: string | null
  ) => void;
}

const BoresNewMap = ({
  segments = [],
  boreCurrentLocation: boreCurrenLocation = null,
  onLocationAdded = () => {},
}: BoresNewMapProps) => {
  const [boreLocation, setBoreLocation] = useState<Location | null>(
    boreCurrenLocation
  );
  const [mapState, setMapState] = useState<any>({
    mapApi: null,
    mapInstance: null,
  });

  useEffect(() => {
    if (boreLocation) {
      const segment = segments.find((segment) => {
        const radius = calculateDistance(
          segment.startLat,
          segment.startLng,
          segment.endLat,
          segment.endLng
        );
        return isWithinRadius(
          boreLocation?.lat,
          boreLocation?.lng,
          segment.startLat,
          segment.startLng,
          segment.endLat,
          segment.endLng,
          radius
        );
      });
      onLocationAdded(boreLocation, segment?.id ?? null);
    }
  }, [boreLocation]);

  useEffect(() => {
    if (boreLocation == null && boreCurrenLocation != null) {
      setBoreLocation(() => boreCurrenLocation);
    }
  }, [boreCurrenLocation]);

  useEffect(() => {
    if (mapState.mapApi && mapState.mapInstance) {
      segments.map((segment) => {
        new mapState.mapApi.Circle({
          strokeColor: "#FF0000",
          strokeOpacity: 0.8,
          clickable: false,
          strokeWeight: 2,
          fillColor: "#FF0000",
          fillOpacity: 0.1,
          map: mapState.mapInstance,
          center: calculateMiddlePoint(
            segment.startLat,
            segment.startLng,
            segment.endLat,
            segment.endLng
          ),
          radius:
            calculateDistance(
              segment.startLat,
              segment.startLng,
              segment.endLat,
              segment.endLng
            ) / 2,
        });
      });
      fitBounds(mapState.mapInstance, mapState.mapApi);
    }
  }, [segments, mapState.mapApi, mapState.mapInstance]);

  const fitBounds = (map: any, maps: any) => {
    const allLocations: Location[] = [];

    if (boreCurrenLocation) allLocations.push(boreCurrenLocation);
    else {
      segments.forEach((segment) => {
        allLocations.push(
          new Location(segment.startLat ?? 0, segment.startLng ?? 0)
        );
        allLocations.push(
          new Location(segment.endLat ?? 0, segment.endLng ?? 0)
        );
      });
    }

    const bounds = new maps.LatLngBounds();
    allLocations?.forEach((location) => {
      bounds.extend(new maps.LatLng(location.lat, location.lng));
    });
    map.fitBounds(bounds);
    maps.event.addDomListenerOnce(map, "idle", () => {
      maps.event.addDomListener(map, maps, "resize", () => {
        map.fitBounds(bounds);
      });
    });
  };

  const getAddress = async (lat: number, lng: number) => {
    const addresses = await getAddressesFromCoordinates(lat, lng);
    const address = getFirstElement(
      addresses.data?.results ?? []
    )?.formatted_address;

    setBoreLocation(() => {
      return new Location(lat, lng, address);
    });
  };

  return (
    <React.Fragment>
      <div className="form-group">
        <Geosuggest
          initialValue={boreLocation?.address ?? ""}
          autoActivateFirstSuggest={true}
          inputClassName="form-control"
          suggestsClassName="sug-container"
          suggestItemClassName="dropdown-item"
          suggestItemActiveClassName="sug-item-active"
          onSuggestSelect={(result: any) => {
            setBoreLocation(
              () =>
                new Location(
                  result?.location?.lat,
                  result?.location?.lng,
                  result?.label
                )
            );
          }}
          autoComplete="off"
        />
      </div>
      <div className="mt-3 mb-3">
        <span className="font-weight-boldest">
          Drop a pin to map to add location to this bore
        </span>
      </div>
      <div style={{ height: "40vh", width: "100%" }}>
        <GoogleMapReact
          options={{
            mapTypeControl: true,
            mapTypeControlOptions: {
              style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
            },
            mapTypeId: mapState.mapInstance?.getMapTypeId() ?? "satellite",
          }}
          bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_KEY ?? "" }}
          center={{
            lat:
              boreLocation?.lat ??
              getFirstElement(segments)?.startLat ??
              36.755716,
            lng:
              boreLocation?.lng ??
              getFirstElement(segments)?.startLng ??
              -121.2444679,
          }}
          zoom={10}
          yesIWantToUseGoogleMapApiInternals={true}
          onGoogleApiLoaded={({ map, maps }) => {
            setMapState({ mapApi: maps, mapInstance: map });
            map.addListener("click", (e: any) => {
              getAddress(e.latLng.lat(), e.latLng.lng());
            });
          }}
        >
          {boreLocation && (
            <EQMarker
              key={103}
              lat={boreLocation?.lat}
              lng={boreLocation?.lng}
              pinColor={"darkgreen"}
            />
          )}
        </GoogleMapReact>
      </div>
    </React.Fragment>
  );
};

export default BoresNewMap;
