import React, { useContext, useState } from "react";
import { functions } from "../../firebase";
import { showToast } from "../../shared/Util";
import { AuthContext } from "../../shared/Auth";
import { CrewMemberEntity } from "../../shared/data/Crew";
import ReportComponent, { ReportRecipient } from "./ReportComponent";

interface DailyBoreReportProps {
  componentId: string;
  jobId: string;
  crewMembers: CrewMemberEntity[];
  boreId: string;
  boreLogId: string;
  strippedDate: string;
  boreNumber: string;
  distance: string;
  distanceToday: string;
  isButton: boolean;
}

const DailyBoreReport = (props: DailyBoreReportProps) => {
  const authContext = useContext(AuthContext);

  const [sending, setSending] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const [saveFile, setSaveFile] = useState(false);

  const sendBoreReport = async (emailsToSend: string[]) => {
    if (!sending) {
      setSending(true);
      let sendJobReportFunc = functions.httpsCallable("boreLogReport");

      await sendJobReportFunc({
        jobID: props.jobId,
        userID: authContext.currentUser.uid,
        recipients: emailsToSend.join(","),
        boreID: props.boreId,
        strippedDate: props.strippedDate,
        boreNo: props.boreNumber,
        distance: props.distance,
        distanceToday: props.distanceToday,
        saveFile: saveFile,
      })
        .then((result) => {
          showToast("success", "Report has been sent successfully.");
        })
        .catch((exception) => {
          showToast("danger", "Error sending report.");
        });
      setSending(false);
    }
  };

  const getDialogItems = () => {
    const user = authContext.currentUser;

    const crewMemberItems: ReportRecipient[] =
      props.crewMembers
        ?.filter((crewMember) => crewMember.id != null)
        .map((crewMember) => {
          return new ReportRecipient(
            crewMember.id ?? "",
            `${crewMember.firstName} ${crewMember.lastName}`,
            crewMember.email ?? "Unknown email"
          );
        }) ?? [];

    const items: ReportRecipient[] = [
      new ReportRecipient(
        user.uid,
        user.displayName ?? "Unknown",
        user.email,
        true,
        true
      ),
    ].concat(crewMemberItems);

    return items;
  };

  return (
    <React.Fragment>
      {!props.isButton ? (
        <a
          onClick={(e) => {
            e.preventDefault();
            setShowModal(true);
          }}
          className="navi-link btn"
        >
          <span className="navi-text">Share Daily Bore Log</span>
        </a>
      ) : (
        <div className="form-group float-right">
          <button
            id="daily-bore-report-btn"
            data-toggle="tooltip"
            onClick={() => {
              if (!sending) setShowModal(true);
            }}
            className={
              sending
                ? "btn btn-primary font-weight-bold mr-4 w-100 spinner spinner-white spinner-right"
                : "btn btn-primary font-weight-bold mr-4 w-100"
            }
          >
            Share Daily Bore Log
          </button>
        </div>
      )}
      <ReportComponent
        id={`daily-bore-log-report-${props.componentId}`}
        title="Daily bore report"
        showDialog={showModal}
        reportRecipients={getDialogItems()}
        onDismiss={() => {
          setShowModal(false);
        }}
        sendReport={(recipients) => {
          setShowModal(false);
          setSending(true);
          sendBoreReport(recipients.map((recipients) => recipients.email));
        }}
      >
        <div className="d-flex align-items-left m-5">
          <label className="checkbox checkbox-lg checkbox-light-primary checkbox-inline flex-shrink-0 m-0">
            <input
              onChange={(value) => {
                setSaveFile(value.target.checked);
              }}
              type="checkbox"
              defaultValue={1}
              checked={saveFile}
            />
            <span />
          </label>
          <div className="d-flex flex-column flex-grow-1 font-weight-bold ml-5">
            <a className="text-dark text-hover-primary mb-1 font-size-lg">
              Save this report to Job Documents
            </a>
          </div>
        </div>
      </ReportComponent>
    </React.Fragment>
  );
};

export default DailyBoreReport;
