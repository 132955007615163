import React, { Component } from "react";
import ReactDOM from 'react-dom';

class AddRemoveModal extends Component {
  state = {
    searchText:"",
    items: this.props.items,
    filteredItems: this.props.items
  };

  filterList = (input, items) => {
    const filteredList = items?.filter((item) => {
      return item.display?.toLowerCase().includes(input.toLowerCase()) || item.additional?.toLowerCase().includes(input.toLowerCase());
    });
    this.setState({ items:items, filteredItems: filteredList });
  };

  constructor(props) {
    super(props);

    this.hiddenBtnRef = React.createRef();
  }

  componentWillReceiveProps = (nextProps) => {
    this.filterList(this.state.searchText, nextProps.items)   
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.show && !prevProps.show) {
      this.hiddenBtnRef.current.click(); 
     }

    if (!this.props.show && prevProps.show) {
      this.hiddenBtnRef.current.click(); 
     }
  }

  render() {
    return ReactDOM.createPortal(
      <React.Fragment>
        <div
          className="modal fade"
          id={"add-delete-modal-" + this.props.id}
          data-backdrop="static"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="staticBackdrop"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-scrollable" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {this.props.title}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={this.props.toggle}
                >
                  <i aria-hidden="true" className="ki ki-close" />
                </button>
              </div>
              <div className="modal-body">
                <input
                  type="text"
                  className="form-control mb-5 pr-1"
                  onChange={(event) => {
                    this.setState({searchText:event.target.value})
                    this.filterList(event.target.value, this.state.items);
                  }}
                />
                {this.state.filteredItems?.map((item, index) => {
                  return (
                    <div
                      key={"chng-clnt" + index}
                      className={item.disabled ? "d-flex align-items-center mb-2" : "d-flex align-items-center mb-2"}
                    >
                      <div className="d-flex flex-column flex-grow-1">
                      <a className={`font-weight-bold font-size-lg ${item.disabled?`text-dark-50 font-italic`:`text-dark-75`}`}>
                          {item.display}
                        </a>
                        <a className={`font-weight-bold font-size-lg text-dark-50 ${item.disabled?`font-italic`:``}`}>
                          {item.additional}
                        </a>
                      </div>
                      <label className={`checkbox checkbox-lg checkbox-inline flex-shrink-0 m-0 mx-4 ${item.disabled ? 'checkbox-light-white':'checkbox-light-success'}`}>
                        <input
                          onChange={() => {
                            this.props.onChangeHandler(item.id);
                          }}
                          disabled={item.disabled}
                          type="checkbox"
                          name="select"
                          checked={item.value}
                        />
                        <span />
                      </label>
                    </div>
                  );
                })}
              </div>
              {this.props.children}
              <div className="modal-footer">
                <button
                  onClick={this.props.save}
                  type="button"
                  className={
                    this.props.saving
                      ? "btn btn-primary font-weight-bold spinner spinner-white spinner-right"
                      : "btn btn-primary font-weight-bold"
                  }
                >
                  {this.props.saveText??"Save"}
                </button>
                <button
                  onClick={() => {
                    //this.setState({ items: this.props.items });
                    this.props.toggle();
                  }}
                  type="button"
                  className="btn btn-light-primary font-weight-bold"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
        <button
          style={{ display: "none" }}
          ref={this.hiddenBtnRef}
          type="button"
          className="btn btn-primary"
          data-toggle="modal"
          data-target={"#add-delete-modal-" + this.props.id}
        ></button>
      </React.Fragment>,
      document.body
    );
  }
}

export default AddRemoveModal;
