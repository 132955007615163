import React, { useState } from "react";

interface EQInputTextProps {
  title: string;
  hint?: string;
  placeholder?: string;
  inputText?: string;
  required?: boolean;
  isNumerical?: boolean;
  numberOfLines?: number;
  onOnInputChange?: (input: string | number) => void;
}

const EQInputText = (props: EQInputTextProps) => {

  const [formState, setFormState] = useState(props)

  const getInputType = () =>{
    if(props.numberOfLines?? 1 >1){
      return (<textarea
        rows={props.numberOfLines}
        className="form-control"
        value={props.inputText}
        placeholder={props.placeholder}
        onChange={(input) => {
          if (props.onOnInputChange)
            props.onOnInputChange(input.target.value);
        }}
      />)
    }

    if(props.isNumerical){
      return (
        <input
            type="number"
            className="form-control"
            value={props.inputText}
            placeholder={props.placeholder}
            pattern="[0-9]*"
            onChange={(input) => {
              if (props.onOnInputChange)
                props.onOnInputChange(Number(input.target.value));
            }}
          />
      )
    }

    return (<input
    key={`eq-input-text-input-${props.title}}`}
      type="text"
      className="form-control"
      placeholder={props.placeholder}
      value={props.inputText}
      onChange={(input) => {
        if (props.onOnInputChange){
          props.onOnInputChange(input.target.value);
        }
      }}
    />)
  }

  return (
    <React.Fragment>
      <div
        key={`eq-input-text-${props.title}}`}
        className="form-group"
      >
        <label>
          {props.title}{" "}
          <span className="text-danger">{props.required ? "*" : null}</span>
        </label>
        {getInputType()}
        {props.hint && <div className="form-text text-muted">{props.hint}</div>}
      </div>
    </React.Fragment>
  );
};

export default EQInputText;
