import React, { Component } from 'react';
import { getClients, addClient, getContacts, updateContact, addContact, updateClient } from '../../../../shared/Api';
import { resetFormValues, touchField, updateFormOnChange, getSubmitClass, fieldsToObject, normalizeInput, objectToFields, isValid } from '../../../../shared/FormHelper';
import { AuthContext } from "../../../../shared/Auth";
import BasicForm from '../../../../components/Form/BasicForm';
import Loader from '../../../../components/Layout/Loader/Loader';
import { Link } from 'react-router-dom';
import { getMessages } from '../../../../components/Messages/GetMessages';
import { ArrowLeft, Company } from '../../../../components/Icons/Svg';
import DefaultList from '../../../../components/Lists/DefaultList';

class ClientDetailsStep extends Component {
    static contextType = AuthContext;

    state = {
        loading: false,
        client: null,
        savingClient: false,
        editContact: false,
        clients: [],
        addNewClientShow: false,
        addNewContactShow: false,
        contact: null,
        search: '',
        currentPage: 1,
        postsPerPage: 4,
        selectedClientId: this.props.obj.job ? this.props.obj.job.clientID : null,
        jobClients: [],
        newClientForm: {
            name: 'add-client',
            isValid: false,
            submitErrorMessage: null,
            loading: false,
            fields: [
                {
                    name: 'companyName',
                    value: '',
                    defaultValue: '',
                    type: 'text',
                    label: 'New Company Name',
                    placeholder: 'New Company Name',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: getMessages('enter_company'),
                    required: true,
                    validators: [
                        {
                            type: 'required',
                            message: getMessages('company_required')
                        }
                    ]
                },
                {
                    name: 'phoneNumber',
                    value: '',
                    defaultValue: '',
                    placeholder: 'Contact Phone',
                    type: 'text',
                    subtype: 'phone',
                    label: 'Contact Phone',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: getMessages('enter_client_phone'),
                    required: true,
                    validators: [
                        {
                            type: 'required',
                            message: getMessages('client_phone_required')
                        },
                        {
                            type: 'phoneNumber',
                            message: getMessages('invalid_phone')
                        }
                    ]
                },
                {
                  name: 'address',
                  value: '',
                  defaultValue: '',
                  placeholder: 'Address',
                  type: 'text',
                  label:'Address',
                  isValid: true,
                  validationMessage: '',
                  isTouched: false,
                  additionalMessage: getMessages('enter_client_address'),
                  required: false,
                },
                {
                  name: "notes",
                  value: "",
                  defaultValue: "",
                  placeholder: "Notes",
                  type: "text",
                  label: "Notes",
                  isValid: true,
                  validationMessage: "",
                  isTouched: false,
                  additionalMessage: getMessages("enter_client_notes"),
                  required: false,
                }
                
            ],
            otherFields: [
                {
                    name: 'firstName',
                    value: '',
                    defaultValue: '',
                    placeholder: 'First Name',
                    type: 'text',
                    label: 'First Name',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: getMessages('enter_client_firstname'),
                    required: true,
                    validators: [
                      {
                        type: 'required',
                        message: getMessages('client_firstname_required')
                      }
                    ]
                  },
                  {
                    name: 'lastName',
                    value: '',
                    defaultValue: '',
                    placeholder: 'Last Name',
                    type: 'text',
                    label: 'Last Name',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: getMessages('enter_client_lastname'),
                    required: true,
                    validators: [
                      {
                        type: 'required',
                        message: getMessages('client_lastname_required')
                      }
                    ]
                  },
                  {
                    name: 'position',
                    value: '',
                    defaultValue: '',
                    placeholder: 'Position',
                    type: 'text',
                    label: 'Position',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: getMessages('enter_contact_position'),
                    required: false
                  },
                  {
                    name: 'email',
                    value: '',
                    defaultValue: '',
                    placeholder: 'Email',
                    type: 'text',
                    label: 'Email',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: getMessages('enter_contact_email'),
                    required: true,
                    validators: [
                      {
                        type: 'required',
                        message: getMessages('client_email_required')
                      },
                      {
                        type: 'email',
                        message: getMessages('invalid_email')
                      }
                    ]
                  },
                  {
                    name: 'contactPhoneNumber',
                    value: '',
                    defaultValue: '',
                    placeholder: 'Phone Number',
                    type: 'text',
                    subtype: 'phone',
                    label: 'Phone Number',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: getMessages('enter_contact_phone'),
                    required: true,
                    validators: [
                      {
                        type: 'required',
                        message: getMessages('client_phone_required')
                      },
                      {
                        type: 'phoneNumber',
                        message: getMessages('invalid_phone')
                      }
                    ]
                  },
                  {
                    name: "phoneNumber2",
                    value: "",
                    defaultValue: "",
                    placeholder: "Other phone number",
                    type: "text",
                    subtype: "phone",
                    label: "Other phone number",
                    isValid: true,
                    validationMessage: "",
                    isTouched: false,
                    additionalMessage: getMessages("enter_contact_phone"),
                    required: false,
                    validators: [
                      {
                        type: "phoneNumber",
                        message: getMessages("invalid_phone"),
                      },
                    ],
                  },
                  {
                    name: "contactNotes",
                    value: "",
                    defaultValue: "",
                    placeholder: "Notes",
                    type: "text",
                    label: "Notes",
                    isValid: true,
                    validationMessage: "",
                    isTouched: false,
                    additionalMessage: getMessages("enter_contact_notes"),
                    required: false,
                  }
            ],
            buttons: [
                {
                    title: 'Add Client',
                    submit: true,
                    className: 'btn btn-primary font-weight-bolder text-uppercase px-9 py-4 mr-2 ',
                    onClick: () => this.addClient(),
                    disabled: () => false
                },
                {
                    title: 'Cancel',
                    className: 'btn btn-light-secondary font-weight-bolder text-uppercase px-9 py-4',
                    onClick: () => this.closeAddNewClientForm(),
                    disabled: () => false
                }
            ]
        },
        contactForm: {
            name: 'add-contact',
            isValid: false,
            submitErrorMessage: null,
            saving: false,
            fields: [
                {
                name: 'firstName',
                value: '',
                defaultValue: '',
                placeholder: 'Contact First Name',
                type: 'text',
                label: 'Contact First Name',
                isValid: true,
                validationMessage: '',
                isTouched: false,
                additionalMessage: getMessages('enter_client_firstname'),
                required: true,
                validators: [
                    {
                    type: 'required',
                    message: getMessages('client_firstname_required')
                    }
                ]
                },
                {
                name: 'lastName',
                value: '',
                defaultValue: '',
                placeholder: 'Contact Last Name',
                type: 'text',
                label: 'Contact Last Name',
                isValid: true,
                validationMessage: '',
                isTouched: false,
                additionalMessage: getMessages('enter_client_lastname'),
                required: true,
                validators: [
                    {
                    type: 'required',
                    message: getMessages('client_lastname_required')
                    }
                ]
                },
                {
                name: 'position',
                value: '',
                defaultValue: '',
                placeholder: 'Position',
                type: 'text',
                label: 'Position',
                isValid: true,
                validationMessage: '',
                isTouched: false,
                additionalMessage: getMessages('enter_contact_position'),
                required: false
                },
                {
                name: 'email',
                value: '',
                defaultValue: '',
                placeholder: 'Contact Email',
                type: 'text',
                label: 'Contact Email',
                isValid: true,
                validationMessage: '',
                isTouched: false,
                additionalMessage: getMessages('enter_contact_email'),
                required: true,
                validators: [
                    {
                    type: 'required',
                    message: getMessages('client_email_required')
                    },
                    {
                    type: 'email',
                    message: getMessages('invalid_email')
                    }
                ]
                },
                {
                name: 'phoneNumber',
                value: '',
                defaultValue: '',
                placeholder: 'Contact Phone',
                type: 'text',
                subtype: 'phone',
                label: 'Contact Phone',
                isValid: true,
                validationMessage: '',
                isTouched: false,
                additionalMessage: getMessages('enter_contact_phone'),
                required: true,
                validators: [
                    {
                    type: 'required',
                    message: getMessages('client_phone_required')
                    },
                    {
                    type: 'phoneNumber',
                    message: getMessages('invalid_phone')
                    }
                ]
                },
                {
                  name: 'phoneNumber2',
                  value: '',
                  defaultValue: '',
                  placeholder: 'Contact Phone 2',
                  type: 'text',
                  subtype: 'phone',
                  label: 'Contact Phone 2',
                  isValid: true,
                  validationMessage: '',
                  isTouched: false,
                  additionalMessage: getMessages('enter_contact_phone'),
                  required: false,
                  validators: [
                    {
                      type: 'phoneNumber',
                      message: getMessages('invalid_phone')
                    }
                  ]
                },{
                  name: "notes",
                  value: "",
                  defaultValue: "",
                  placeholder: "Notes",
                  type: "text",
                  label: "Notes",
                  isValid: true,
                  validationMessage: "",
                  isTouched: false,
                  additionalMessage: getMessages("enter_contact_notes"),
                  required: false,
                }
            ],
            buttons: [
                {
                    title: 'Add Contact',
                    submit: true,
                    className: 'btn btn-primary font-weight-bolder text-uppercase px-9 py-4 mr-2 ',
                    onClick: () => this.addContactToClient(),
                    disabled: () => false
                },
                {
                    title: 'Cancel',
                    className: 'btn btn-light-secondary font-weight-bolder text-uppercase px-9 py-4',
                    onClick: () => this.closeAddNewContactForm(),
                    disabled: () => false
                }
            ]
        }
    };

    componentDidMount = async () => {
        window.scrollTo(0, 0);
        
        if (!this.props.obj || !this.props.obj.job) {
            this.props.history.push('/jobs/new');
            return;
        }
            
        this.props.setStep('client');
        await this.loadClients();
        this.loadSelectedClientId();
    }

    next = () => {
        this.props.setJobClients(this.state.jobClients ?? [])
        this.props.setJobClient(this.state.selectedClientId ?? null);
        this.props.history.push('/jobs/new/crews');
    }

    loadSelectedClientId = () => {
        this.setState({ selectedClientId: this.props.obj.job.clientID, jobClients: this.props.obj.job.jobClients});
    }

    setSelectedClient = (clientId) => {
        this.setState({ selectedClientId: clientId });
    }

    loadClients = async () => {
        this.setState({ loading: true });
        const currentUser = this.context.currentUser;
        const inventoryId = currentUser.company.inventoryID;

        const clientsResponse = await getClients(inventoryId);
        const clients = clientsResponse.data;

        clients.forEach(async (x) => {
            
            const getContactsResult = await getContacts(inventoryId, x.id);
            const contactList = getContactsResult.data;
            
            if(x.firstName || x.lastName) {
                contactList.unshift({ id: x.id, firstName: x.firstName, lastName: x.lastName, position: '', email: '', phoneNumber: '' }); 
            }
            x.contacts = contactList;
            } 
        );

        clients.sort((a, b) => {
          if (a.companyName.toLowerCase().trim() < b.companyName.toLowerCase().trim()) {
            return -1;
          }
          if (a.companyName.toLowerCase().trim() > b.companyName.toLowerCase().trim()) {
            return 1;
          }
          return 0;
        })

        this.setState({ clients: clients, loading: false });
    }

    addClient = async () => {
        this.setState({savingClient: true})
        const currentUser = this.context.currentUser;
        const inventoryId = currentUser.company.inventoryID;
        const contactFields = this.state.newClientForm.otherFields;
        const client = fieldsToObject(this.state.newClientForm.fields);

        const addedClient = await addClient(inventoryId, client);

        if(isValid(contactFields) && addedClient.status === 'success'){
            const contactObj = {};
            contactFields.forEach(item => {
                if (item.name === "contactPhoneNumber") {
                  contactObj.phoneNumber = item.value;
                } else if (item.name === "contactNote") {
                  contactObj.note = item.value;
                } else {
                  contactObj[item.name] = item.value;
                }
                return contactObj
              });
              const addedContact = await addContact(inventoryId, addedClient.data, contactObj);
        }
        
        this.closeAddNewClientForm();
        this.setState({savingClient: false})
        this.loadClients();
    }

    editContact = async () => {
        const form = {...this.state.contactForm}
        const client = this.state.client;
        form.saving = true;
        this.setState({contactForm: form})
        
        const obj = fieldsToObject(this.state.contactForm.fields);

        const clientId = this.state.client.id;
        const contactId = this.state.contact.id;
        const currentUser = this.context.currentUser;
        const inventoryId = currentUser.company.inventoryID;

        if(clientId === contactId) {
            await updateClient(inventoryId, clientId, { firstName: '', lastName: '' });
            const addedContact = await addContact(inventoryId, clientId, obj);
        } else {
            const addedContact = await updateContact(inventoryId, clientId, contactId, obj);
        }

        const getContactsResult = await getContacts(inventoryId, clientId);
        const contactList = getContactsResult.data;
        client.contacts = contactList;
        form.saving = false;
        this.setState({contactForm: form, client: client});
        this.closeEditContactForm();
        this.loadClients();
    }

    addContactToClient = async () => {
        const form = {...this.state.contactForm}
        form.saving = true;
        this.setState({contactForm: form})
        
        const client = this.state.client;
        const currentUser = this.context.currentUser;
        const inventoryId = currentUser.company.inventoryID;
        

        const obj = fieldsToObject(this.state.contactForm.fields);
        const addedContact = await addContact (inventoryId, client.id, obj);
        const getContactsResult = await getContacts(inventoryId, client.id);
        const contactList = getContactsResult.data;
        client.contacts = contactList;
        form.saving = false;
        
        this.setState({contactForm: form, client: client});
        this.closeAddNewContactForm();
        this.loadClients();
    }

    getClass = (className) => {
        if(this.props.saving)
            className += " spinner spinner-white spinner-right";

        return className;
    }

    onSelectClientChange = (e, client) => {
        const newClientId = this.state.selectedClientId === client.id ? null : client.id;
        const checked = e.currentTarget.checked

        const jobClients = checked
        ? [
            ...this.state?.jobClients??[],
           {clientID: client.id ?? "", contactIDs: []},
          ]
        : this.state.jobClients.filter(
            (c) => c.clientID !== client.id
          );

        this.setState({ selectedClientId: newClientId, jobClients: jobClients});
    }

    closeAddNewClientForm = () => {
        let updatedForm = { ...this.state.newClientForm };

        resetFormValues(updatedForm);
        updatedForm.isValid = false;
        updatedForm.submitErrorMessage = '';
        updatedForm.loading = false;

        this.setState({
            newClientForm: updatedForm,
            addNewClientShow: false,
        });
    }

    prepareAddNewClientForm = () => {
        let form = this.state.newClientForm;
        
        form.handleInputChange = (event, obj) => {
            const updatedForm = updateFormOnChange(event, obj, this.state.newClientForm);

            if (!updatedForm)
                return;

            this.setState({ form: updatedForm });
        }

        form.touchField = (event) => {
            const updatedForm = touchField(event, this.state.newClientForm);

            if (!updatedForm)
                return;

            this.setState({ form: updatedForm });
        }

        return form;
    }

    clients = () => {
        if (!this.state.search)
            return this.state.clients;

        return this.state.clients.filter(x => x.companyName.toLowerCase().includes(this.state.search.toLowerCase())
            || ((x.firstName + ' ' + x.lastName).toLowerCase().includes(this.state.search.toLowerCase())));
    }

    resetClientDetails = () => {
        this.setState({
            client: null,
            addNewContactShow: false
        });
    }

    paginate=(pageNumber) => {
        this.setState({currentPage: pageNumber});
      }
    
    prepareAddNewContactForm = () => {
        let form = this.state.contactForm;

        form.handleInputChange = (event, obj) => {
            const updatedForm = updateFormOnChange(event, obj, this.state.contactForm);

            if (!updatedForm)
                return;

            this.setState({ contactForm: updatedForm });
        }

        form.touchField = (event) => {
            const updatedForm = touchField(event, this.state.contactForm);

            if (!updatedForm)
                return;

            this.setState({ contactForm: updatedForm });
        }
        form.buttons = [
            {
                title: 'Add Contact',
                submit: true,
                className: 'btn btn-primary font-weight-bolder text-uppercase px-9 py-4 mr-2 ',
                onClick: () => this.addContactToClient(),
                disabled: () => false
            },
            {
                title: 'Cancel',
                className: 'btn btn-light-secondary font-weight-bolder text-uppercase px-9 py-4',
                onClick: () => this.closeAddNewContactForm(),
                disabled: () => false
            }
        ]
        return form;
    }

    prepareEditContactForm = () => {
        let form = this.state.contactForm;

        form.handleInputChange = (event, obj) => {
            
            const updatedForm = updateFormOnChange(event, obj, this.state.contactForm);
    
            if (!updatedForm)
                return;
    
            this.setState({ contactForm: updatedForm });
        }
    
        form.touchField = (event) => {
            const updatedForm = touchField(event, this.state.contactForm);
    
            if (!updatedForm)
                return;
    
            this.setState({ contactForm: updatedForm });
        }

        form.buttons = [
            {
                title: 'Save',
                submit: true,
                className: 'btn btn-primary font-weight-bolder text-uppercase px-9 py-4 mr-2 ',
                onClick: () => this.editContact(),
                disabled: () => false
            },
            {
                title: 'Cancel',
                className: 'btn btn-light-secondary font-weight-bolder text-uppercase px-9 py-4',
                onClick: () => this.closeEditContactForm(),
                disabled: () => false
            }
        ] 
        return form;    
    }

    closeAddNewContactForm = () => {
        let updatedForm = { ...this.state.contactForm };
        resetFormValues(updatedForm);
        updatedForm.isValid = false;
        updatedForm.submitErrorMessage = '';
        updatedForm.loading = false;

        this.setState({
            contactForm: updatedForm,
            addNewContactShow: false,
            currentPage: 1
        });
    }

    closeEditContactForm = () => {
        let updatedForm = { ...this.state.contactForm };
        resetFormValues(updatedForm);
        updatedForm.isValid = false;
        updatedForm.submitErrorMessage = '';
        updatedForm.loading = false;

        this.setState({
            contactForm: updatedForm,
            contact: null,
            currentPage: 1
        });
    }

    switchToEditForm = (contact) => {
        const form = { ...this.state.contactForm };
        const fields = objectToFields(form.fields, contact);

        form.fields = fields;
        this.setState({ contactForm: form, contact: contact });
    }

    render() {
        const addNewClientForm = this.prepareAddNewClientForm();
        const addNewContactForm = this.prepareAddNewContactForm();
        const clients = this.clients();
        const currentUser = this.context.currentUser;
        let editContactForm = null;
        
        if(this.state.contact){
             editContactForm = this.prepareEditContactForm();
        }
        return (
          <>
            {this.state.client &&
              !this.state.contact &&
              !this.state.addNewContactShow && (
                <div className="card card-custom">
                  <div className="card-header h-auto py-4">
                    <div className="card-title">
                      <h3 className="card-label">
                        Client details
                        <span className="d-block text-muted pt-2 font-size-sm">
                          {this.state.client.companyName}
                        </span>
                      </h3>
                    </div>
                    <div className="card-toolbar">
                      <a
                        onClick={this.resetClientDetails}
                        className="btn btn-secondary-light btn-sm font-weight-bold mr-4"
                      >
                        <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                          <ArrowLeft />
                        </span>
                        Back to List
                      </a>
                    </div>
                  </div>
                  <div className="card-body py-4">
                    <div className="form-group row my-2">
                      <label className="col-4 col-form-label">
                        Company name:
                      </label>
                      <div className="col-8">
                        <span className="form-control-plaintext font-weight-bolder">
                          {this.state.client.companyName}
                        </span>
                      </div>
                    </div>
                    <div className="form-group row my-2">
                      <label className="col-4 col-form-label">
                        Phone number:
                      </label>
                      <div className="col-8">
                        <span className="form-control-plaintext font-weight-bolder">
                          {normalizeInput(this.state.client.phoneNumber, "")}
                        </span>
                      </div>
                    </div>
                    <div className="form-group row my-2">
                      <label className="col-4 col-form-label">
                        Address:
                      </label>
                      <div className="col-8">
                        <span className="form-control-plaintext font-weight-bolder">
                          {this.state.client.address}
                        </span>
                      </div>
                    </div>
                    <div className="form-group row my-2">
                      <label className="col-4 col-form-label">
                        Notes:
                      </label>
                      <div className="col-8">
                        <span className="form-control-plaintext font-weight-bolder">
                          {this.state.client.notes}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="card-title">
                      <h6 className="card-label">
                        Contacts
                        <span className="d-block text-muted pt-2 font-size-sm">
                          {this.state.client.companyName}
                        </span>
                        <span className="d-block text-muted pt-2 font-size-sm">
                          To edit contact, just click the name.
                        </span>
                      </h6>
                    </div>
                    <button
                      onClick={() => this.setState({ addNewContactShow: true })}
                      className="btn btn-block btn-light-primary font-weight-bold mb-8"
                    >
                      <i className="ki ki-plus icon-md mr-2" />
                      Add New Contact
                    </button>
                    <DefaultList
                      data={this.state.client.contacts}
                      currentPage={this.state.currentPage}
                      postsPerPage={this.state.postsPerPage}
                      canSelect={true}
                      onSelected={(item) => {
                        const contactAlreadyAdded = this.state.jobClients?.find(
                            (jobClient) =>
                              jobClient.clientID == this.state.client.id
                          )
                          ?.contactIDs.some(
                            (contactId) => contactId == item.id
                          );
                          
                        var updatedJobClients;
                        if (contactAlreadyAdded) {
                          //remove selected contact
                          updatedJobClients = this.state.jobClients?.map(
                            (jobClient) => {
                              if (
                                jobClient.clientIDs?.some(
                                  (clientId) => clientId == item.id
                                )
                              ) {
                                return {
                                  ...jobClient,
                                  contactIDs: jobClient?.contactIDs.filter(
                                    (contactId) => contactId != item.id
                                  ),
                                };
                              } else {
                                return jobClient;
                              }
                            }
                          )??[];
                        } else {
                          //add new contact
                          updatedJobClients = this.state.jobClients?.map(
                            (jobClient) => {
                              if (jobClient?.clientID==this.state.client?.id) {
                                return {...jobClient, contactIDs: [...jobClient.contactIDs, item.id]}
                              } else {
                                return jobClient;
                              }
                            }
                          )??[];
                        }

                        this.setState({jobClients:updatedJobClients})
                      }}
                      selectedItems={
                        this.state.jobClients?.find(
                          (jobClient) =>
                            jobClient.clientID == this.state.client.id
                        )?.contactIDs ?? []
                      }
                      paginate={this.paginate}
                      currentUser={currentUser}
                      mode="client"
                      switchToEditForm={this.switchToEditForm}
                      {...this.props}
                    />
                  </div>
                </div>
              )}
            {this.state.client && this.state.contact && (
              <>
                <h3 className="mb-10 font-weight-bold text-dark">
                  Contact Edit
                </h3>
                <div className="mb-20">
                  <BasicForm {...editContactForm} />
                </div>
              </>
            )}
            {this.state.client &&
              !this.state.contact &&
              this.state.addNewContactShow && (
                <>
                  <h6 className="mb-10 font-weight-bold text-dark">
                    Add Contact for {this.state.client.companyName}
                  </h6>
                  <div className="mb-20">
                    <BasicForm {...addNewContactForm} />
                  </div>
                </>
              )}
            {!this.state.client && (
              <>
                <h3 className="mb-10 font-weight-bold text-dark">Client</h3>

                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => {
                      this.setState({ search: e.target.value });
                    }}
                    placeholder="Search"
                  />
                </div>

                <button
                  onClick={() => this.setState({ addNewClientShow: true })}
                  className="btn btn-block btn-light-primary font-weight-bold mb-8"
                >
                  <i className="ki ki-plus icon-md mr-2" />
                  Add New Client
                </button>

                {this.state.addNewClientShow && (
                  <div className="mb-20">
                    <BasicForm
                      {...addNewClientForm}
                      saving={this.state.savingClient}
                    />
                  </div>
                )}
                {this.state.loading ? (
                  <Loader height="100px" />
                ) : (
                  <div>
                    {clients.length > 0 ? (
                      <div className="navbar-nav-scroll">
                        {clients.map((client, index) => (
                          <div
                            key={"clnts-dtls-stp" + index}
                            className="d-flex align-items-center mb-10"
                          >
                            <div
                              role="button"
                              onClick={() => {
                                this.setState({ client: client });
                              }}
                              className="d-flex flex-column flex-grow-1 font-weight-bold"
                            >
                              <a className="text-dark text-hover-primary mb-1 font-size-lg">
                                {client.companyName}
                              </a>
                            </div>
                            <label className="checkbox checkbox-lg checkbox-light-primary checkbox-inline flex-shrink-0 m-0 mx-4">
                              <input
                                type="checkbox"
                                onChange={(e) =>
                                  this.onSelectClientChange(e, client)
                                }
                                checked={this.state?.jobClients?.some(
                                  (c) => c.clientID == client.id
                                )}
                              />
                              <span />
                            </label>
                          </div>
                        ))}
                        <span className="text-muted">
                          Click on a client to edit or add/edit contacts
                        </span>
                      </div>
                    ) : (
                      <div className="d-flex align-items-center mb-10">
                        <div className="d-flex flex-column flex-grow-1 font-weight-bold">
                          <div className="mainpage-graphic">
                            <Company
                              width={"200px"}
                              height={"200px"}
                              opacity={"0.3"}
                            />
                          </div>
                          <span className="font-italic">
                            You don't have any clients added to the job at the
                            moment. Click the button above to start adding them.
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                <div className="justify-content-between border-top mt-5 pt-10">
                  <button
                    className="btn btn-primary font-weight-bolder text-uppercase px-9 py-4 float-right ml-2"
                    onClick={this.next}
                  >
                    Next
                  </button>
                  <button
                    className={this.getClass(
                      "btn btn-primary font-weight-bolder text-uppercase px-9 py-4 float-right ml-2"
                    )}
                    onClick={() => {
                      this.props.setJobClients(this.state.jobClients ?? []);
                      this.props.setJobClient(this.state.selectedClientId ?? null);
                      this.props.save();
                    }}
                  >
                    Skip and Save
                  </button>
                  <Link
                    className="btn btn-light-secondary font-weight-bolder text-uppercase px-9 py-4 float-right ml-2"
                    to="/jobs/new/location"
                  >
                    Back
                  </Link>
                </div>
              </>
            )}
          </>
        );
    }
}

export default ClientDetailsStep;